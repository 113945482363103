/**
*
* Forum
*
**/

//  Forum Landing

.forum-topic-legend {
  padding: 1em 0;
  margin: 2em 0 0 0;
  border-top: 1px solid $grey-200;
  div {
    display: inline-block;
    margin: 0 0.25em 0 0;
    padding: 0 0.5em 0 0;
    font-family: $fontTitle;
    @include font-size(14px);
    border-right: 1px solid $grey-200;
    &:last-child {
      padding: 0;
      margin: 0;
      border: none;
    }
  }
}

//  Forum Specific Overrides

.node-type-forum .action-links a {
  background: $green;
  color: $white;
  &:hover {
    background: $yellow;
  }
}

.node-type-forum .comment-wrapper {
  padding: 0;
  border: none;
}

//  Forum Styles

.forum-post {
  padding: 1em 0;
}

.forum-topic-header {
  font-family: $fontTitle;
}

.forum-post-info {
  margin: 0 0 2em;
  padding: 1em;
  border: 1px solid $grey-200;
  font-family: $fontTitle;
}

.topic-post-count {
  float: right;
}

.topic-reply-link {
  float: left;
  a {
    margin: 0 1em 0 0;
  }
}

.forum-posted-on {
  float: left;
}

.forum-post-number {
  float: right;
}

.forum-post {
  .forum-post-title,
  .post-title {
    margin: 0 0 0.5em;
    font-family: $fontTitle;
    @include font-size(24px);
  }
}

.forum-post-panel-sub {
  float: left;
  width: 140px;
  margin: 0 0 1em;
}

.author-pane {
  padding: 0.5em;
  margin: 0 1em 0 0;
  border: 1px solid $grey-200;
  a {
    display: block;
    margin: 0 0 1em;
    font-family: $fontTitle;
    @include font-size(14px);
  }
}

.user-picture {
  img {
    display: block;
    width: 100%;
  }
}

.forum-post-content .field-comment-body p {
  @include font-size(13px);
}

.post-edited {
  font-family: $fontTitle;
  @include font-size(14px);
}

.forum-post-links {
  width: 100%;
}

.forum-post-links {
  .post-edit a,
  .post-delete a {
    @include button;
    font-weight: normal;
  }
}

.forum-post-links .links li {
  display: inline-block;
}

.forum-post-links li.statistics_counter {
  display: block;
  float: right;
  margin: 0;
  text-align: right;
  font-family: $fontTitle;
  @include font-size(14px);
}

.forum-post-links li.comment-add {
  margin: 0;
}

.forum-jump-links {
  display: none;
}