/**
*
* Groups
*
**/

.view-groups .views-row {
  @include clearfix;
  padding: 1.5em 0;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.view-groups .views-row h3 {
  margin: 0 0 0.5em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
}

.group-image {
  display: inline-block;
  width: 50px;
  margin: 0 1em 0 0;
  vertical-align: top;
  @include mq($from: tablet) {
    width: 100px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.view-group .group-data-container {
  display: inline-block;
  @include font-size(13px);
  width: 75%;
}

.group-about {
  @include font-size(13px);
  margin: 0 0 0.25em;
}

.group-meta-data-one,
.group-meta-data-two {
  font-family: $fontTitle;
}

.group-country,
.group-subject,
.group-keywords {
  font-weight: $bold;
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid $grey-400;
  a {
    color: $grey-400;
    &:hover {
      color: $green;
    }
  }
  &:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.group-meta-data-two span {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid $grey-200;
  &:last-child {
    border: none;
  }
}

.group-country {
  text-transform: uppercase;
  a {
    color: $yellow;
  }
}

// Groups  page

.pane-group-image-and-video-view {
  margin: 0 0 1.5em;
  img {
    width: 100%;
    height: auto;
  }
}

.group-mission-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
}

.group-about-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
}

.group-creation-date-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
  font-family: $fontTitle;
  @include font-size(18px);
  .views-label {
    font-weight: $bold;
  }
}

.group-site-pane {
  margin: 0 0 1em;
  font-family: $fontTitle;
  font-weight: $bold;
}