/**
*
* Lists
*
**/

ul, ol {
  list-style-position: inside;
}

ul {
  list-style-type: disc;
}

.region-content {
  ul, ol {
    margin: 0 0 1em 2em;
  }
}

.links.inline {
  list-style-type: none;
}