/**
*
* Sharethis buttons
*
**/

.pane-node-sharethis {
	margin: 2em 0 1em;
}

.pane-node-sharethis h2 {
	@include font-size(20px);
	margin: 0 0 0.25em;
}

.sharethis-buttons {
  padding: 0.5em 0;
  height: 43px;
}

.stButton span.stLarge {
	background-size: 24px;
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	margin: 0 0.5em 0 0;
	border-radius: 4px;
	overflow: hidden;
	&:hover {
		background-position: 0 0;
	}
}