/**
*
* Global
*
**/

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

::selection {
    background: $yellow;
    color: $white;
}