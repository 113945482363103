/**
*
* Action links
*
**/

.action-links {
  padding: 1em 0 0 0;
  border-top: 1px solid $grey-200;
  border-bottom: 1px solid $grey-200;
  a {
    display: block;
    padding: 5px 10px;
    margin: 0 1em 1em 0;
    background: $grey-100;
    font-family: $fontTitle;
    &:hover, &.active {
      background: $green;
      color: $white;
    }
  }
  &:last-child a {
    margin-right: 0;
  }
}