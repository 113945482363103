/**
*
* Links
*
**/

a {
  color: $green;
  transition: .5s;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: darken($green, 20);
  }
  &:visited {
    text-decoration: none;
    outline: 0;
  }
}