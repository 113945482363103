/**
*
* Footer
*
**/

.footer-main {
  padding: 2em  0;
  background: $grey-100;
  border-top: 1px solid darken($grey-100, 5);
}

// Footer Menu

.block-menu-menu-footer-menu {
  margin: 0.5em 0 1em;
  @include mq($from: desktop) {
    width: 45%;
    float: left;
  }
}

.block-menu-menu-footer-menu .menu {
  list-style-type: none;
  li {
    display: inline-block;
    margin: 0 .5em 0 0;
    padding: 0 .5em 0 0;
    border-right: 1px solid $grey-200;
    line-height: 1;
    &:last-child {
      padding: 0;
      border: none;
    }
  }
  a {
    color: $green;
    @include font-size(15px);
    font-family: $fontTitle;
    &:hover {
      color: $black;
    }
  }
}

// Social Links

.block-social-media-links {
  margin: 0 0 1em;
  @include mq($from: desktop) {
    float: right;
    text-align: right;
  }
}

.social-media-links {
  li {
    display: inline-block;
    margin: 0 .5em 0 0;
    &:last-child {
      margin: 0;
    }
  }
  a {
    display: inline-block;
    &:hover {
      opacity: 0.75;
    }
  }
  img {
    display: inline-block;
    width: 32px;
    height: 32px;
  }
}

// Partner Logos

.block-views-partners-block {
  clear: both;
  padding: 2em 0;
  border-top: 1px solid $grey-200;
  @include clearfix;
}

.block-views-partners-block h2 {
  margin: 0 0  1em;
  @include font-size(18px);
  color: $grey-500;
  font-family: $fontTitle;
  font-weight: $normal;
}

.block-views-partners-block .views-row {
  display: inline-block;
  margin: 0 2em 0 0;
  &:last-child {
    margin: 0;
  }
  a {
    display: block;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  img {
    display: block;
    max-width: 100px;
  }
}

// Copyright

.copyright {
  padding: 1em 0 0;
  border-top: 1px solid $grey-200;
  @include font-size(13px);
  color: $grey-500;
  font-family: $fontTitle;
}