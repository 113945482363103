/**
*
* Tags
*
**/

.tags li {
  display: inline;
  a {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 1em 1em 0;
    background: $grey-100;
    font-family: $fontTitle;
    &:hover, &.active {
      background: $grey-400;
      color: $white;
    }
  }
  &:last-child a {
    margin-right: 0;
  }
}