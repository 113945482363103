/**
*
* Pager
*
**/

.pager {
  padding: 1.5em 0;
  border-top: 1px solid $grey-200;
  text-align: center;
}

.sidebar-right .pager {
  margin: 0.5em 0 0 0;
  padding: 1em 0 0 0;
  border-top: 1px solid $grey-200;
}

.pager li {
  display: inline-block;
  font-family: $fontTitle;
  a {
    display: inline-block;
  }
}

.pager-current {
  padding: 0 0.5em;
}

.pager-item a {
  padding: 0 0.5em;
}

.pager-next,
.pager-last {
  padding: 0 0.25em;
}

.pager-current {
  color: $yellow;
}