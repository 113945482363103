/**
*
* Main Nav
*
**/

.main-nav {
  background: $grey-100;
  border-bottom: 1px solid darken($grey-100, 5);
}

// Mobile Toggle

.block-system-main-menu {
  display: none;
  @include mq($from: tablet) {
    display: block;
  }
}

.block-system-main-menu.expand {
  display: block;
}

.mobile-menu-btn {
  display: block;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  text-indent: -999em;
  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0.8em;
    width: 2em;
    height: 0.30em;
    background: $grey-800;
    box-shadow: 0 0.5em 0 0 $grey-800, 0 1em 0 0 $grey-800;
  }
  @include mq($from: tablet) {
    display: none;
  }
}

.block-system-main-menu .menu li {
  list-style-type: none;
  border-bottom: 1px solid darken($grey-100, 5);
  @include mq($from: tablet) {
    display: inline-block;
    border-right: 1px solid darken($grey-100, 5);
    border: none;
  }

  &:first-child {
    border-top: 1px solid darken($grey-100, 5);
  }
  &:last-child {
    border: none;
  }
}

.block-system-main-menu .menu li a {
  display: block;
  padding: 10px 0;
  text-transform: uppercase;
  font-family: $fontTitle;
  font-weight: $bold;
  @include font-size(13px);
  line-height: 1;
  &:hover, &.active {
    color: $dark-green;
  }
  @include mq($from: tablet) {
    @include font-size(14px);
    padding: 12px 15px;
  }

  @include mq($from: desktop) {
    padding: 15px 20px;
    @include font-size(15px);
  }
}