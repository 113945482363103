/**
*
* Front
*
**/

// Banner Section

.front-banner {
  min-height: 400px;
  background: $grey-200 url(../images/front-banner.jpg);
  background-size: cover;
  padding: 4em 0 2em 0;
  border-bottom: 1px solid $grey-200;
}

.intro {
  @include mq($from: desktop) {
    width: 70%;
  }
}

.intro p {
  @include font-size(24px);
  font-family: $fontTitle;
  font-weight: $bold;
  line-height: 1.3;
  color: $white;
  text-shadow: 1px 0 1px rgba($black, 0.25);
  @include mq($from: desktop) {
    @include font-size(30px);
  }
}

.register-button {
  display: inline-block;
  background: $yellow;
  padding: 10px 30px;
  margin: 0 10px 1em 0;
  color: $white;
  @include font-size(16px);
  font-family: $fontTitle;
  font-weight: $bold;
  text-transform: uppercase;
  transition: all 0.3s;
  &:hover {
    background: $green;
    color: $white;
  }
}

//  Business Areas Section

.front .front-cta-section .contain {
  @include mq($from: desktop) {
    width: 68em;
  }
  @include mq($from: wide) {
    width: 94em;
    .view-business-areas h2 {
      min-height: 1.3em;
    }
  }
}
.front-cta-section {
  padding: 3em 0;
  background: $grey-100;
}

.view-business-areas {
  @include clearfix;
  text-align: center;
}

.view-business-areas .views-row {
  margin: 0  0 1em;
  vertical-align: top;
  background: $white;
  padding: 1em;
  @include mq($from: desktop) {
    width: 22.5%;
    min-height: 18em;
    margin: 0 2% 0 0;
    display: inline-block;
  }

  &:last-child {
    margin: 0;
  }
}

.view-business-areas h2 {
  display: table;
  margin: 0 auto 1em;
  @include font-size(16px);
  line-height: 1.3;
  min-height: 2.6em;
  text-transform: uppercase;
  a {
    display: table-cell;
    vertical-align: middle;
  }
}

.biz-area-image {
  img {
    display: inline-block;
    margin: 0 0 1em;
    border-bottom: 7px solid $yellow;
    @include mq($from: desktop) {
      width: 90%;
      height: auto;
    }
  }
}

.view-business-areas .biz-area-description {
  @include font-size(13px);
  line-height: 1.3;
  margin: 0 0 2em;
}

.view-business-areas .biz-area-btn {
  a {
    @include button;
  }
}

// Map Section

.front-map-section {
  padding: 2em 0 0 0;
  text-align: center;
}

.front-map-section .pane-title {
  margin: 0 0 0.5em;
  @include font-size(30px);
  line-height: 1.3;
}

.front-map-section .views-exposed-widget {
  label {
    display: none;
  }
}

.front-map-section .views-exposed-widget .form-item .form-item .form-item {
  display: inline-block;
  margin: 0 1em 0 0;
  padding: 0 1em 0 0;
  border-right: 1px solid $grey-200;
  &:last-child {
    margin: 0;
    padding: 0;
    border: none;
  }
}

.front-map-section form {
  margin: 0 0 2em;
}

.front-map-section .views-submit-button {
  display: none;
}

// Featured

.front-featured-section {
  padding: 3em 0;
  background: $grey-100;
}

.front-featured-section h2 {
  text-transform: uppercase;
}

//  Featured Content  Block

.featured-content-pane {
  @include clearfix;
  @include mq($from: desktop) {
    width: 50%;
    min-height: 220px;
    float: left;
    padding: 0 2em 0 0;
    border-right: 1px solid $grey-200;
  }
}

.featured-content-pane .featured-media {
  @include mq($from: desktop) {
    width: 50%;
    min-width: 200px;
    padding: 0 0 1em 1em;
    float: right;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

h3.featured-content-title {
  @include font-size(18px);
  margin: 1em 0 0.5em;

  @include mq($from: desktop) {
    margin-top: 0;
  }
}

.featured-content-body {
  @include font-size(13px);
  margin: 0 0 0.5em;
}

.featured-content-body a {
  font-weight: $bold;
}

.featured-content-meta-data {
  font-family: $fontTitle;
  color: $grey-500;
}

.featured-content-author {
  padding: 0 0.5em 0 0;
}

//  Featured Group  Block

.featured-group-pane {
  @include clearfix;
  margin-top: 2em;
  @include mq($from: desktop) {
    min-height: 220px;
    width: 47%;
    float: right;
    margin-top: 0;
  }
}

.featured-group-pane .featured-media {
  @include mq($from: desktop) {
    width: 50%;
    min-width: 200px;
    padding: 0 0 1em 1em;
    float: right;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

h3.featured-group-title {
  @include font-size(18px);
  margin: 1em 0 0.5em;

  @include mq($from: desktop) {
    margin-top: 0;
  }
}

.featured-group-body {
  @include font-size(13px);
  margin: 0 0 0.5em;
}

.featured-group-body a {
  font-weight: $bold;
}

.featured-group-meta-data {
  font-family: $fontTitle;
  color: $grey-500;
}

.featured-group-user {
  padding: 0 0.5em 0 0;
}

//  Feeds Section

.front-feeds-section {
  padding: 3em 0;
}

.feed-section-one {
  @include mq($from: desktop) {
    width: 45%;
    float: left;
  }
}

.feed-section-two {
  @include mq($from: desktop) {
    width: 45%;
    float: right;
  }
}

// Latest content

.latest-content-pane h2 {
  text-transform: uppercase;
  padding: 0 0 1em;
  margin: 0 0 0.5em;
  border-bottom: 1px solid $grey-200;
}

.view-latest-content {
  margin: 0 0 2em;
}

.view-latest-content .views-row {
  @include clearfix;
  padding: 1em 0;
  border-bottom: 1px solid $grey-200;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border: none;
  }
}

.latest-content-title {
  .main-content & {
    @include font-size(16px);
    line-height: 1.5;
    margin: 0;
    .title-label {
      font-weight: normal;
      color: $grey-600;
    }
  }
}

.latest-content-flag {
  @include font-size(15px);
  padding: 0 0 0 0.5em;
  margin: 0 0 0 0.25em;
  border-left: 1px solid $grey-200;
  color: $yellow;
  white-space: nowrap;
}

.latest-content-image {
  float: left;
  margin: 0.5em 1em 0.5em  0;
  width: 100px;
  a {
    display: block;
  }
  img {
    width: 100px;
    height: auto;
    display: block;
  }
}

.latest-content-body {
  @include font-size(13px);
  margin: 0 0 0.25em;
}

.latest-content-meta-data-one {
  margin: 0 0 0.25em;
  font-family: $fontTitle;
  font-weight: $bold;
  @include font-size(13px);
  a {
    color: $grey-400;
    &:hover {
      color: $green;
    }
  }
}

.latest-content-meta-data-one .latest-content-country {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid $grey-200;
  a {
    color: $yellow;
  }
}

.latest-content-meta-data-one span {
    padding: 0 0.5em 0 0;
    margin: 0 0.5em 0 0;
    border-right: 1px solid $grey-200;
    &:last-child {
      border: none;
      padding: 0;
      margin: 0;
    }
}

.latest-content-meta-data-two {
  font-family: $fontTitle;
  @include font-size(13px);
  .latest-content-author {
    padding: 0 0.5em 0 0;
  }
}


// Twitter

.twitter-pane {
  margin: 0 0 1.75em;
  padding: 0 0 1em;
  border-bottom: 1px solid $grey-200;
}

.twitter-pane h2 {
  text-transform: uppercase;
  padding: 0 0 1em;
  border-bottom: 1px solid $grey-200;
}

//  Latest Activity

.latest-activity-pane h2 {
  text-transform: uppercase;
  margin: 0 0 0.5em;
  padding: 0 0 1em;
  border-bottom: 1px solid $grey-200;
}

.view-latest-activity .views-row {
  padding: 0.5em 0;
  border-bottom: 1px solid $grey-200;
  @include font-size(13px);
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border: none;
  }
}