/**
*
* Tabs
*
**/

//  Drupal's default tabs

.tabs-container {
  margin: 0  0 1em;
}

.tabs li {
  display: inline-block;
  a {
    display: block;
    padding: 5px 10px;
    margin: 0 1em 1em 0;
    background: $grey-100;
    font-family: $fontTitle;
    &:hover, &.active {
      background: $green;
      color: $white;
    }
  }
  &:last-child a {
    margin-right: 0;
  }
}

//  jQuery Tabs

.ui-tabs-nav {
  margin: 0 0 1em;
}

.ui-tabs-nav li {
  display: inline-block;
  a {
    display: block;
    padding: 8px 16px;
    margin: 0 1em 1em 0;
    background: $green;
    font-family: $fontTitle;
    font-weight: $bold;
    text-transform: uppercase;
    color: $white;
    &:hover, &.active {
      background: $yellow;
      color: $white;
    }
  }
  &:last-child a {
    margin-right: 0;
  }
  &.ui-state-focus a {
    outline: 0;
    background: $yellow;
    color: $white;
  }
}