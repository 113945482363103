/**
*
* Problem Solving
*
**/

// Problem Solving View

.problem-solving-intro {
  padding: 0 0 1em;
  margin: 0 0 2em;
  border-bottom: 1px solid $grey-200;
}

.view-problems .views-row {
  @include clearfix;
  padding: 1.5em 0;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.view-problems .views-row h3 {
  margin: 0 0 0.2em;
  line-height: 1.5em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
}

.view-problems .problem-image {
  display: inline-block;
  width: 50px;
  margin: 0.5em 1em 0.5em 0;
  vertical-align: top;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.view-problems .problem-content-container {
  display: inline-block;
  width: 75%;
}

.problem-content-meta-data-one {
  font-family: $fontTitle;
  margin: 0 0 0.25em;
}

.problem-content-created {
  margin: 0 0 0 0.5em;
}

.problem-content-meta-data-two {
  margin: 0 0 0.25em;
  font-family: $fontTitle;
  font-weight: $bold;
  .problem-country a {
    color: $yellow;
    padding: 0 0.5em 0 0;
    margin: 0 0.25em 0 0;
    border-right: 1px solid $grey-200;
  }
  > a:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.problem-content-meta-data-two a {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid $grey-200;
  color: $grey-400;
  &:hover {
    color: $green;
  }
}

.problem-content-meta-data-three {
  font-family: $fontTitle;
  span {
    padding: 0 0.25em 0 0;
    margin: 0 0.25em 0 0;
  }
  .problem-answers {
    padding: 0 0 0 0.25em;
    margin: 0 0 0 0.25em;
  }
  .problem-follow-count {
    border-left: 1px solid $grey-200;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
  }
}

.view-problem-solve .problem-body {
  margin: 0 0 0.5em;
}

//  Problem page

.pane-node-field-problem {
  border-top: 1px solid $grey-200;
  padding: 1em 0 0 0;
  margin: 0 0 0.5em;
  font-family: $fontTitle;
  @include font-size(26px);
  line-height: 1;
  font-weight: $bold;
}

.pane-node-field-problem .field-problem:before {
  content: 'Question:';
  color: $yellow;
}

.node-type-problem .pane-node-author,
.node-type-problem .pane-node-submitted,
.node-type-problem .pane-node-created {
  display: inline-block;
  font-family: $fontTitle;
  .pane-content {
    display: inline-block;
  }
}

.problem-content-sms-id,
.problem-content-answer-count {
  display: inline-block;
  font-family: $fontTitle;
  float: right;
  margin: 0 0 0 1em;
}

.problem-comments-pane {
  margin: 2em 0 0;
}

.problem-comments-pane .comment {
  padding: 1em;
  border: 1px solid $grey-200;
}

.problem-comments-pane .title {
  color: $yellow;
}

h4.problem-follow-title,
h4.problem-actions-title {
  margin: 0 0 0.5em;
}

.problem-sms-button-pane,
.problem-email-button-pane,
.problem-reject-button-pane,
.problem-attention-button-pane,
.problem-close-button-pane {
  display: inline-block;
  padding: 0.5em 0;
}

.problem-email-button-pane,
.problem-close-button-pane {
  margin: 0 0 1em;
}

.problem-assignee-pane,
.problem-group-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid $grey-200;
  font-family: $fontTitle;
  @include font-size(16px);
  .pane-title {
    font-weight: $bold;
    color: $grey-400;
    vertical-align: top;
  }
  .pane-content {
    display: inline-block;
  }
}

.problem-group-pane {
  margin: 0 0 1.5em;
}

.problem-country-pane,
.problem-subject-area-pane,
.problem-keywords-pane {
  border-bottom: none;
  padding: 0.25em 0;
  .field-label {
    @include font-size(16px);
    font-family: $fontTitle;
    font-weight: $bold;
    color: $grey-400;
    margin: 0 0.5em 0 0;
  }
}

.problem-comments-pane .comment .field-comment-body-container {
  padding: 0;
  margin: 0 0 1.5em;
  @include font-size(13px);
}
