/**
*
* Messages
*
**/

.messages-cta a {
  @include button;
  @include font-size(16px);
  &:after {
    @include icon;
    @include font-size(14px);
    content: "a";
    display: inline-block;
    margin: 0 0 0 0.5em;
    color: $bright-yellow;
  }
  &:hover:after {
    color: $white;
  }
}

.privatemsg-list .marker {
  color: $red;
}