/**
*
* User
*
**/

.toboggan-unified {
  #login-message {
    margin: 0 0 1em;
    padding: 0 0 1em;
    border-bottom: 1px solid $grey-200;
  }
  #login-links {
    margin: 0 0 1em;
    padding: 0 0 1em;
    border-bottom: 1px solid $grey-200;
    a {
      display: inline-block;
      border-right: 1px solid $grey-200;
      padding: 0 1em 0 0;
      margin: 0 1em 0 0;
      font-weight: $bold;
      &.lt-active {
        color: darken($yellow, 10);
      }
      &:last-child {
        margin: 0;
        padding: 0;
        border: none;
      }
    }
  }
  #register-form legend {
    display: block;
    margin: 0 0 0.5em;
    @include font-size(18px);
    font-weight: $bold;
  }
  .login-forgot {
    margin: 0 0 1em;
  }
}