/**
*
* People
*
**/

//  People's View

.people-grpoups-orgs .views-row {
  @include clearfix;
  padding: 1.5em 0;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.user-list-picture {
  display: inline-block;
  width: 50px;
  @include mq($from: tablet) {
    width: 100px;
  }
  margin: 0 1em 0 0;
  vertical-align: top;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.view-people .user-data-container {
  display: inline-block;
  @include font-size(13px);
  width: 75%;
}

h3.user-name {
  font-family: $fontTitle;
  font-weight: $bold;
  @include font-size(18px);
  margin: 0 0 0.5em;
}

.user-meta-data-role {
  font-weight: $normal;
  padding: 0 0 0 0.5em;
  margin: 0 0 0 0.25em;
  border-left: 1px solid $grey-200;
}

.user-meta-data-one,
.user-meta-data-two {
  font-family: $fontTitle;
}

.user-country,
.user-specialization,
.user-keywords {
  font-weight: $bold;
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid $grey-200;
  a {
    color: $grey-400;
    &:hover {
      color: $green;
    }
  }
  &:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.user-country a {
  color: $yellow;
  text-transform: uppercase;
}

.user-meta-data-two span {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid $grey-200;
  &:last-child {
    border: none;
  }
}

// .user-join-date {
//   padding: 0 0.5em 0 0;
//   margin: 0 0.25em 0 0;
//   border-right: 1px solid $grey-200;
// }