/**
*
* Tables
*
**/

table {
  width: 100%;
  background: $white;
  margin: 0 0 2em;
}

th, td {
  border: 1px solid $grey-200;
  padding: 0.5em;
  @include font-size(12px);
}

th {
  background: $grey-100;
}