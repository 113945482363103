/**
*
* Layout
*
**/

// This is a  Mobile first layout using "min-width" and  additional breakpoints for larger screens.

// Default styles  will apply to mobile and up.

// Add a "tabletish" breakpoint that will only kick in after 740px by: @include mq($from: tablet) {...}

// Add a "large / desktop" breakpoint that will kick in after 980px by: @include mq($from: desktop) {...}

.contain {
  @include clearfix;
  padding: 0 1em;
  @include mq($from: tablet) {
    width: 80%;
    margin: 0 auto;
    padding: 0;
  }
}

.not-front .main-content {
  @include clearfix;
  padding: 1em;
  @include mq($from: tablet) {
    width: 80%;
    margin: 0 auto;
    padding: 2em 0;
  }
}

// Custom Panel Layouts

// Main Content Left

.main-content-left {
  @include clearfix;
  @include mq($from: desktop) {
    width: 65%;
    float: left;
    margin: 0 2em 0 0;
  }
}

.sidebar-right {
  @include mq($from: desktop) {
    width: 30%;
    float: left;
  }
}

// Main Content Right

.main-content-right {
  @include mq($from: desktop) {
    width: 65%;
    float: left;
  }
}

.sidebar-left {
  @include mq($from: desktop) {
    width: 30%;
    float: left;
    margin: 0 2em 0 0;
  }
}