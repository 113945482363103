/**
*
* Resources
*
**/

// Resources List

.view-resources .views-row {
  @include clearfix;
  padding: 1.5em 0;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.view-resources .views-row h3 {
  margin: 0 0 0.5em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .res-flag {
    @include font-size(15px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
}

.view-resources .resource-image {
  float: left;
  width: 100px;
  margin: 0 1em 2em 0;
  img {
    display: block;
    width: 100px;
    height: auto;
  }
}

.view-resources .field-image-container {
  display: inline-block;
  width: 50px;
  @include mq($from: tablet) {
    width: 100px;
  }

  margin: 0.5em 1em 0.5em 0;
  vertical-align: top;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.view-resources .res-content-container {
  display: inline-block;
  width: 75%;
}

.res-description {
  @include font-size(13px);
  margin: 0 0 0.25em;
}

.res-content-meta-data-one {
  margin: 0 0 0.25em;
  font-family: $fontTitle;
  font-weight: $bold;
}

.res-content-meta-data-one .res-content-country {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid $grey-200;
  a {
    color: $yellow;
  }
}

.res-content-meta-data-one a {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid $grey-200;
  color: $grey-400;
  &:hover {
    color: $green;
  }
  &:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.res-content-meta-data-two {
  font-family: $fontTitle;
  .res-content-author {
    padding: 0 0.5em 0 0;
  }
}

.view-resources .resource-body {
  margin: 0 0 0.5em;
}

.view-resources .resource-body a {
  font-weight: $bold;
}

// Resources Page

.node-type-resource .title {
  margin: 0 0 0.25em;
  span {
    margin: 0 0.1em 0 0;
    font-weight: $normal;
    color: $grey-600;
  }
}

.node-type-resource {
  .pane-node-field-real-author,
  .pane-node-created {
    font-family: $fontTitle;
    display: inline-block;
    margin: 0 1em 1em 0;
    div {
      display: inline;
    }
  }
}

.res-country-forum-content-pane,
.res-afaas-content-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid $grey-200;
  .pane-title {
    display: inline;
    @include font-size(16px);
    font-weight: $bold;
    color: $grey-400;
    font-family: $fontTitle;
  }
  .pane-content {
    display: none;
  }
}