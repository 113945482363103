/**
*
* Filters
*
**/

.view-people,
.view-groups,
.view-problem-solve,
.view-resources,
.view-opps,
.view-stories,
.view-news,
.view-orgs {
  .views-exposed-form {
    padding: 0 0 1.5em;
    border-bottom: 1px solid $grey-200;
  }
  .views-exposed-widget {
    @include mq($from: desktop) {
      margin: 0 10px 0 0;
    }

    @include mq($from: desktop) {
      float: left;
      width: 17%;
    }

    label {
      @include font-size(12px);
    }
    //  Left column filter widths
    .main-content-left & {
      @include mq($from: desktop) {
        float: left;
        width: 30%;
      }
    }
  }
  .views-exposed-widget.views-submit-button,
  .views-exposed-widget.views-reset-button {
    display: inline-block;
    margin: 0 10px 0 0;
    @include mq($from: desktop) {
      margin: 1.5em 10px 0 0;
      width: auto;
    }

    input[type="submit"] {
      margin: 0;
    }
  }
  .views-exposed-widget.views-reset-button {
    input[type="submit"] {
      background: $grey-300;
      &:hover {
        background: $yellow;
      }
    }
  }
}

// Chosen overrides

.chosen-container {
  @include font-size(12px);
  color: $grey-600;
}

.chosen-container-single .chosen-single {
  height: 37px;
  line-height: 37px;
  border-color: $grey-200;
  background: $white;
  border-radius: 0;
  box-shadow: none;
  color: $grey-600;
}

.chosen-container-single .chosen-single div {
  top: 6px;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  border-color: $grey-300;
  box-shadow: none;
  background-image: none;
}

.chosen-container .chosen-drop {
  border-radius: 0;
  box-shadow: none;
  border-color: $grey-300;
}

.chosen-container .chosen-results li.highlighted {
  box-shadow: none;
  background-image: none;
  background: $green;
}

.chosen-container-multi .chosen-choices {
  box-shadow: none;
  background-image: none;
  border-color: $grey-200;
  padding: 3px 0;
  margin: 0;
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  font-family: $fontBody;
}