/**
*
* Forms
*
**/

.main-content {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    width: 100%;
    display: block;
    background: none;
    border: 1px solid $grey-200;
    background-color: $white;
    padding: 9px;
    appearance: none;
    box-shadow: none;
    transition: border 0.3s;
    @include font-size(12px);
    color: $blue;
    font-family: $fontBody;
  }
  input[type="submit"] {
    display: inline-block;
    border: none;
    background: $green;
    padding: 9px 20px;
    margin: 0 10px 1em 0;
    cursor: pointer;
    color: $white;
    @include font-size(12px);
    font-family: $fontTitle;
    text-transform: uppercase;
    transition: all 0.3s;
    &:hover {
      background: $yellow;
      color: $white;
    }
  }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus,
  select:focus,
  textarea:focus {
    outline: none;
    border-color: $grey-300;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  input[type="checkbox"] {
    display: inline-block;
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
  select {
    display: block;
    width: 100%;
    height: 40px;
    border: 1px solid $grey-200;
    background-color: $white;
    color: $grey-600;
    transition: border 0.3s;
  }
  input.error[type="radio"] {
    outline: 1px solid $red;
  }
  input.error[type="radio"]:checked {
    outline: none;
  }
}

.not-front .form-item {
  display: block;
  margin: 0 0 1em;
  @include font-size(13px);
  label {
    display: block;
    margin: 0 0 0.25em;
  }
}

.main-content .form-required {
  color: $red;
}