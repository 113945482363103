/**
*
* Animations
*
**/

// FadeIn

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}