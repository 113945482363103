/**
*
* Comments
*
**/

.comment-wrapper {
  padding: 1.5em 0 0 0;
  border-top: 1px solid $grey-200;
}

.comment {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
}

.comment-content {
  margin: 0 0 1em;
}

.comment-add {
  margin: 0 0  1.5em;
}

.pane-group-field-comments {
  padding: 0 0 1.5em;
}

.comment .links li {
  display: inline;
  list-style-type: none;
}

.comment-wrapper .ckeditor_links {
  float: right;
}

.comment-wrapper .unauth_notice {
  display: block;
  padding: 1em 0;
}

// Comment Styling

.indented {
  padding-left: 1em;
}

.comment {
  padding: 1.5em 0;
}

.comment .form-type-textarea label {
  display: none;
}

.comment-details {
  @include clearfix;
  margin: 0 0 1em;
}

.comment .user-details {
  float: left;
}

.comment .user-image {
  width: 60px;
  img {
    width: 100%;
    height: auto;
  }
}

.comment .user-name {
  @include font-size(13px);
  font-family: $fontTitle;
}

.comment .comment-date {
  float: right;
  line-height: 40px;
  vertical-align: top;
  font-family: $fontTitle;
}

.statistics_counter {
  margin: 0 0 1.5em;
  @include font-size(13px);
}

.comment .new {
  float: right;
  margin: 0.5em 0 0 1em;
  padding: 0.35em 0.5em;
  line-height: 1;
  border-radius: 2px;
  background: $yellow;
  color: white;
  font-family: $fontTitle;
}

.flag-send_to_questioner_by_sms .unflag-disabled {
  float: right;
  margin: 0.5em 0 0 1em;
  padding: 0.35em 0.5em;
  line-height: 1;
  border-radius: 2px;
  background: $yellow;
  color: white;
  font-family: $fontTitle;
}

.comment .field-comment-body-container {
  padding: 1em 0;
}

h2.comment-form {
  border-top: 1px solid $grey-200;
  padding: 0.75em 0 0 0;
  margin: 0.5em 0 0.5em 0;
}

.comment-form .form-item {
  font-family: $fontTitle;
  @include font-size(14px);
  label {
    display: inline;
  }
}
