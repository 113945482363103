/**
*
* Profile
*
**/

// Profile Page Left  Column

.user-image-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid $grey-200;
  img {
    width: 100%;
    height: auto;
    display: inline-block;
    margin: 0;
  }
}

//  User Organizations

.user-orgs-pane,
.user-additional-details-pane {
  .pane-title {
    @include font-size(18px);
    padding: 0.75em;
    margin: 0;
    background: $grey-100;
    border: 1px solid $grey-200;
    border-bottom: none;
  }
  .pane-content {
    padding: 1em;
    margin: 0 0 1.5em;
    border: 1px solid $grey-200;
  }
}

.user-orgs-pane .user-org {
  padding: 0.25em;
  font-family: $fontTitle;
  @include font-size(16px);
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}

//  Additional user info

.user-additional-details-pane .user-joined {
  padding: 0 0 1em;
  border-bottom: 1px solid $grey-200;
  @include font-size(13px);
  font-family: $fontTitle;
}

.user-additional-details-pane .user-additional-contact-details {
  padding: 1em 0;
  border-bottom: 1px solid $grey-200;
  @include font-size(12px);
}

.user-additional-details-pane .user-skills-interests {
  padding: 1em 0;
  border-bottom: 1px solid $grey-200;
  font-family: $fontTitle;
  .user-skills-interest-label {
    display: block;
    margin: 0 0 0.25em;
    font-weight: bold;
    color: $grey-500;
  }
  a {
    margin: 0 0.25em 0 0;
    padding: 0 0.5em 0 0;
    border-right: 1px solid $grey-200;
    &:last-child {
      margin: 0;
      padding: 0;
      border: none;
    }
  }
}

.user-additional-details-pane .user-profile-country {
  padding: 1em 0;
  border-bottom: 1px solid $grey-200;
  font-family: $fontTitle;
}

.user-additional-details-pane .account-websites {
  padding: 1em 0;
  border-bottom: 1px solid $grey-200;
  font-family: $fontTitle;
}

.user-additional-details-pane .user-social-acounts {
  padding: 1em 0;
  margin: 0 0 1em;
  border-bottom: 1px solid $grey-200;
}

//  Social links

.user-social-acounts {
  margin: 0 0 1em;
}

.user-social-acounts a {
  margin: 0 0.25em 0 0;
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  text-indent: -9999em;
}

.user-social-acounts a:after {
  position: absolute;
  top: 0;
  left: 0;
  @include icon;
  margin: 0 0 0 -6px;
  text-indent: 0;
  @include font-size(32px);
  line-height: 0.75;
}

.user-social-acounts .facebook-account:after {
  content: "C";
  color: #3b5998;
}

.user-social-acounts .twitter-account:after {
  color: #4099FF;
  content: "A";
}

.user-social-acounts .linkedin-account:after {
  color: #0077b5;
  content: "B";
}

.user-social-acounts a:hover:after {
  opacity: 0.9;
}

//  User  buttons

.msg-me-button {
  @include button;
  font-weight: $normal;
  margin: 0 0 1em;
}

.user-follow-pane .flag {
  @include button;
  font-weight: $normal;
  margin: 0 0 1em;
}

// Profile Page Right Column

.user-about-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1em;
  border-bottom: 1px solid $grey-200;
}

// User groups

.user-groups-pane {
  margin: 0 0 1em;
  font-family: $fontTitle;
  @include mq($from: desktop) {
    width: 47%;
    float: left;
    margin: 0 5% 0 0;
  }

  .pane-title {
    margin: 0 0 0.5em;
  }
}

.user-content-pane {
  margin: 0 0 1em;
  font-family: $fontTitle;
  @include mq($from: desktop) {
    width: 47%;
    float: left;
    margin: 0;
  }

  .pane-title {
    margin: 0 0 0.5em;
  }
}

.user-opps-pane {
  margin: 0 0 1em;
  font-family: $fontTitle;
  @include mq($from: desktop) {
    width: 50%;
  }

  .pane-title {
    margin: 0 0 0.5em;
  }
}

// User Views

.user-groups-pane,
.user-opps-pane,
.user-content-pane {
  .view .views-row {
    padding: 0.25em 0;
    border-bottom: 1px solid $grey-200;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: none;
    }
  }
}