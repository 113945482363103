/**
*
* Help Centre
*
**/


// Configure FAQ link (admin only)
.faq-index-pane .pane-content > a {
  @include button;
  margin: 0 0 2em;
}


// Page Layout

.page-helpcentre .sidebar-left {
  @include mq($from: desktop) {
    width: 250px;
  }
}

.page-helpcentre .main-content-right {
  margin: 2em 0 0;
  @include mq($from: desktop) {
    width: 500px;
    margin: 0;
    padding: 0 0 0 2em;
  }
}


// FAQ Index Pane

.faq-index-pane ul {
  list-style-type: none;
  ul > ul, ul > li {
    padding: 0 0 0 0.5em;
  }
  li {
    position: relative;
  }
}
.faq-index-pane li > a {
  position: relative;
  &:after {
    @include icon;
    content: "q";
    display: block;
    position: absolute;
    top: 0.9em;
    right: 0.5em;
    font-size: 1.2em;
    letter-spacing: -0.25em;
    margin: 0 0 0 0.5em;
    color: $grey-300;
  }
}

.faq-index-pane li.expanded a {
  border-bottom: 1px solid $grey-200;
}

.faq-index-pane li.expanded > a:after {
  content: "p";
}

.faq-index-pane li.leaf > a:after,
.faq-index-pane a.ajax-processed:after {
  display: none;
}

.faq-index-pane ul a {
  display: block;
  padding: 1em 0;
  @include font-size(12px);
}

.faq-index-pane .ajax-progress-throbber {
  display: initial;
}

.faq-index-pane .throbber {
  float: none;
  position: absolute;
  right: 0.25em;
  top:0.75em;
  background: transparent url(../images/ajax-loader.gif) no-repeat center center;
  width: 16px;
  height: 16px;
}


// FAQ Content Pane

.faq-content-pane {
  @include font-size(13px);
}

.field-faq-block {
  margin: 2.5em 0;
}

.field-faq-block h5 {
  color: $grey-600;
  color: $yellow;
  // font-weight: $normal;
}

.field-faq-block ol,
.field-faq-block ul {
  margin: 0.5em 0 1em 1em;
  padding: 0.25em 0 1em 1em;
  list-style-position: inherit;
}

.field-faq-block ul {
  list-style-type: circle;
}

.field-faq-block li {
  margin: 0.5em 0;
}


// FAQ Contact Support Form

.faq-contact-form {
  @include font-size(13px);
  margin: 2em 0 0;
}

.faq-contact-form h2 {
  @include font-size(20px);
}

.contact-support-text {
  margin: 0 0 1.5em;
}
