/**
*
* Header
*
**/

.header-main {
  padding: 1em 0;
  background: $green;
  border-bottom: 7px solid $yellow;
}

.logo {
  display: block;
  margin: 0 0 1em;
  float: none;
  width: 240px;
  @include mq($from: tablet) {
    float: left;
    margin: 0 1em 1em 0;
  }

  @include mq($from: desktop) {
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
  }
}

//  Header menu

.block-system-user-menu {
  width: 100%;
  clear: both;
  text-align: left;
  @include mq($from: desktop) {
    width: 68%;
    clear: none;
    float: right;
    text-align: right;
  }
}

.block-system-user-menu .menu {
  list-style-type: none;
  li {
    display: inline-block;
    margin: 0 .25em 0 0;
    padding: 0 .5em 0 0;
    border-right: 1px solid $light-green;
    line-height: 1;
    &:last-child {
      padding: 0;
      border: none;
    }
  }
  a {
    color: $white;
    @include font-size(13px);
    font-family: $fontTitle;
    &:hover {
      color: $bright-yellow;
    }
  }
  .msg-link:after,
  .create-content-link:after,
  .dashbord-link:after,
  .ask-a-question-link:after,
  .help-centre-link:after,
  .admin-people-link:after {
    @include icon;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0  5px;
    color: $bright-yellow;
  }
  .msg-link:after {
    content: "a";
  }
  .create-content-link:after {
    content: "u";
  }
  .dashbord-link:after {
    content: "k";
  }
  .ask-a-question-link:after {
    content: "Q";
  }
  .help-centre-link:after {
    content: "m";
  }
  .admin-people-link:after {
    content: "N";
  }
}

// Header Menu 2

.block-menu-menu-log-in-out {
  margin: 0 0 0.5em 0;
  @include mq($from: tablet) {
    text-align: right;
    width: 30%;
    float: right;
    margin: 0.25em 1em 1em 0;
  }
}

.block-menu-menu-log-in-out .menu {
  list-style-type: none;
  li {
    display: inline-block;
    margin: 0 .5em 0 0;
    padding: 0 .5em 0 0;
    border-right: 1px solid $light-green;
    line-height: 1;
    &:last-child {
      padding: 0;
      border: none;
    }
  }
  a {
    color: $white;
    @include font-size(13px);
    font-family: $fontTitle;
    &:hover {
      color: $bright-yellow;
    }
  }
}

// Login button

.not-logged-in .block-menu-menu-log-in-out {
  margin: 0 1em 0 0;
  a {
    padding: 9px 1em;
    background: $yellow;
    display: inline-block;
    &:hover {
      background: lighten($green, 7);
      color: $white;
    }
  }
}

// Header Search

.block-search-form {
  margin: 0 0 .75em;
  @include mq($from: tablet) {
    width: 200px;
    float: right;
  }
}

.block-search-form .form-item-search-block-form {
  display: block;
  width: 140px;
  float: left;
}

.block-search-form .form-text {
  width: 100%;
  background: none;
  border: none;
  background-color: $white;
  padding: 7px;
  appearance: none;
  box-shadow: none;
  transition: border 0.3s;
  @include font-size(12px);
  color: $green;
  font-family: $fontBody;
  &:focus {
    outline: none;
  }
}

.block-search-form .form-submit {
  display: inline-block;
  border: none;
  background: lighten($green, 7);
  padding: 10px;
  cursor: pointer;
  color: $white;
  @include font-size(12px);
  font-family: $fontTitle;
  line-height: .95;
  transition: all 0.3s;
  &:hover {
    background: lighten($green, 10);
    color: $white;
  }
}