/**
*
* Typography
*
**/

body {
  @include font-size(14px);
  line-height: 1.5;
  font-family: $fontBody;
  font-weight: $normal;
  color: $grey-700;
  font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $fontTitle;
  line-height: 1;
  color: $grey-700;
  font-weight: $bold;
}

.main-content {
  h2, h3, h4, h5, h6,
  blockquote, p, address {
    margin: 0 0 0.75em;
  }
}

h1 {
  @include font-size(30px);
  margin: 0 0 0.75em;

}

h2 {
  @include font-size(26px);
}

h3 {
  @include font-size(20px);
}

h4 {
  @include font-size(18px);
}

h5 {
  @include font-size(16px);
}

h6 {
  @include font-size(14px);
}

strong {
  font-weight: $bold;
}

em {
  font-style: italic;
}

blockquote {
  display: block;
  padding: 1em 3em 1em 2.5em;
  position: relative;
  @include font-size(16px);
  line-height: 1.2;
  font-style: italic;
  &:before {
    content: "\201C";
    @include font-size(60px);
    font-weight: $bold;
    color: $grey-400;
    font-style: normal;
    position: absolute;
    left: 0;
    top: 5px;
  }
  &::after {
    content: "";
  }
}