/**
*
* Dashboard
*
**/

.page-dashboard .title {
  border-bottom: 1px solid #F0B03B;
  padding-bottom: 0.5em;
}

.user-dashboard-section-one {
  @include clearfix;
}

.user-dashboard-view-one {
  @include mq($from: desktop) {
    float: left;
    width: 48%;
  }
}

.user-dashboard-view-two {
  @include mq($from: desktop) {
    float: right;
    width: 48%;
  }
}

.view-dashboard-content .views-row {
  padding: 1em 0;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.view-dashboard-opps .views-row {
  padding: 1em 0;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

//// Latest Content

// Tabs

.dashboard-latest-content .ui-tabs-panel {
  border: 1px solid $grey-200;
}

.dashboard-latest-content > .item-list {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.dashboard-latest-content .ui-tabs-nav {
  @include font-size(0px);
  margin: 0;
  border-bottom: 2px solid $green;
  font-size: 0;
  li {
    width: 50%;
  }
  a {
    @include font-size(14px);
    font-weight: $normal;
    width: 100%;
    margin: 0;
    background: white;
    color: $grey-600;
    text-align: center;
    border: 1px solid $grey-200;
    border-bottom: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .ui-state-active a {
    background: $green;
    border: 1px solid $green;
    border-bottom: none;
    color: white;
  }
}

// Network and Public Content

.dashboard-network-public-content h4 {
  @include font-size(16px);
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .flag {
    @include font-size(15px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
  .fav-content-meta-data {
    font-family: $fontTitle;
    font-weight: $bold;
  }
}

.dashboard-network-public-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.dashboard-network-public-content .network-public-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
}

.dashboard-network-public-content .network-public-content-container {
  margin: 0 0 0 5em;
}

.dashboard-network-public-content .network-public-content-title {
  @include font-size(16px);
  line-height: 1.5em;
  margin: 0 0 0.25em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .network-public-content-flag {
    @include font-size(13px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
}

.dashboard-network-public-content .network-public-content-meta-data {
  font-family: $fontTitle;
  font-weight: $normal;
  color: $grey-600;
}

.dashboard-network-public-content .network-public-content-author {
  margin: 0 0.5em 0 0;
}

// Relevant to Me

.dashboard-relevant-content h4 {
  @include font-size(16px);
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .flag {
    @include font-size(15px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
  .fav-content-meta-data {
    font-family: $fontTitle;
    font-weight: $bold;
  }
}

.dashboard-relevant-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.dashboard-relevant-content .user-relevant-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
}

.dashboard-relevant-content .user-relevant-content-container {
  margin: 0 0 0 5em;
}

.dashboard-relevant-content .user-relevant-content-title {
  @include font-size(16px);
  line-height: 1.5em;
  margin: 0 0 0.25em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .user-relevant-content-flag {
    @include font-size(13px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
}

.dashboard-relevant-content .user-relevant-content-meta-data {
  font-family: $fontTitle;
  font-weight: $normal;
  color: $grey-600;
}

.dashboard-relevant-content .user-relevant-content-author {
  margin: 0 0.5em 0 0;
}

//// My (User) Content

.dashboard-user-content {
  margin: 2em 0;
  @include mq($from: desktop) {
    margin: 0;
  }
}

// Tabs

.dashboard-user-content > .item-list {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.dashboard-user-content .ui-tabs-nav {
  @include font-size(0px);
  margin: 0;
  border-bottom: 2px solid $green;
  font-size: 0;
  li {
    width: 33.33%;
    @include mq($from: wide) {
      width: auto;
    }
  }
  a {
    @include font-size(14px);
    font-weight: $normal;
    width: 100%;
    margin: -2px 0 0;
    background: white;
    color: $grey-600;
    text-align: center;
    border: 1px solid $grey-200;
    border-bottom: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .ui-state-active a {
    background: $green;
    border: 1px solid $green;
    border-bottom: none;
    color: white;
  }
}

// My Favourite Content / Bookmarked

.dashboard-favourite-content {
  margin: 0 0 2em;
  border: 1px solid $grey-200;
}

.dashboard-favourite-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.dashboard-favourite-content .fav-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
}

.dashboard-favourite-content .fav-content-container {
  margin: 0 0 0 5em;
}

.dashboard-favourite-content .fav-content-title {
  @include font-size(16px);
  line-height: 1.5em;
  margin: 0 0 0.25em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .fav-content-flag {
    @include font-size(13px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
}

.dashboard-favourite-content .fav-content-meta-data {
  font-family: $fontTitle;
  font-weight: $normal;
  color: $grey-600;
}

.dashboard-favourite-content .fav-content-author {
  margin: 0 0.5em 0 0;
}

// My Favourite Groups, Organisations and Opportunities

.dashboard-favourite-groups {
  margin: 0 0 2em;
  border: 1px solid $grey-200;
}

.dashboard-favourite-groups .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.dashboard-favourite-groups .fav-group-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
}

.dashboard-favourite-groups .fav-group-container {
  margin: 0 0 0 5em;
}

.dashboard-favourite-groups .fav-group-title {
  @include font-size(16px);
  line-height: 1.5em;
  margin: 0 0 0.25em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .fav-group-flag {
    @include font-size(13px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
}

.dashboard-favourite-groups .fav-group-meta-data {
  font-family: $fontTitle;
  font-weight: $normal;
  color: $grey-600;
}

.dashboard-favourite-groups .fav-group-date,
.dashboard-favourite-groups .fav-group-members {
  margin: 0 0.5em 0 0;
}

// Draft Content

.dashboard-draft-content {
  margin: 0 0 2em;
  border: 1px solid $grey-200;
}

.dashboard-draft-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.dashboard-draft-content .draft-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
}

.dashboard-draft-content .draft-content-container {
  margin: 0 0 0 5em;
}

.dashboard-draft-content .draft-content-title {
  @include font-size(16px);
  line-height: 1.5em;
  margin: 0 0 0.25em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .draft-content-flag {
    @include font-size(13px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
}

.dashboard-draft-content .draft-content-meta-data {
  font-family: $fontTitle;
  font-weight: $normal;
  color: $grey-600;
}

.dashboard-draft-content .draft-content-author {
  margin: 0 0.5em 0 0;
}

// Published Content

.dashboard-published-content {
  margin: 0 0 2em;
  border: 1px solid $grey-200;
}

.dashboard-published-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.dashboard-published-content .published-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
}

.dashboard-published-content .published-content-container {
  margin: 0 0 0 5em;
}

.dashboard-published-content .published-content-title {
  @include font-size(16px);
  line-height: 1.5em;
  margin: 0 0 0.25em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .published-content-flag {
    @include font-size(13px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
}

.dashboard-published-content .published-content-meta-data {
  font-family: $fontTitle;
  font-weight: $normal;
  color: $grey-600;
}

.dashboard-published-content .published-content-author {
  margin: 0 0.5em 0 0;
}

// My (Joined) Groups, Organisations and Opportunities

.dashboard-joined-groups {
  margin: 0 0 2em;
  border: 1px solid $grey-200;
}

.dashboard-joined-groups .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

.dashboard-joined-groups .joined-group-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
}

.dashboard-joined-groups .joined-group-container {
  margin: 0 0 0 5em;
}

.dashboard-joined-groups .joined-group-title {
  @include font-size(16px);
  line-height: 1.5em;
  margin: 0 0 0.25em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
  .joined-group-flag {
    @include font-size(13px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
}

.dashboard-joined-groups .joined-group-meta-data {
  font-family: $fontTitle;
  font-weight: $normal;
  color: $grey-600;
}

.dashboard-joined-groups .joined-group-date,
.dashboard-joined-groups .joined-group-members {
  margin: 0 0.5em 0 0;
}

//// Approvals

.dashboard-approval-content {
  margin: 2em 0;
}

// Tabs

.dashboard-approval-content > .item-list {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.dashboard-approval-content .ui-tabs-nav {
  @include font-size(0px);
  margin: 0;
  border-bottom: 2px solid $green;
  font-size: 0;
  li {
    width: 33.33%;
    @include mq($from: desktop) {
      width: auto;
    }
  }
  a {
    @include font-size(14px);
    font-weight: $normal;
    width: 100%;
    margin: -2px 0 0;
    background: white;
    color: $grey-600;
    text-align: center;
    border: 1px solid $grey-200;
    border-bottom: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .ui-state-active a {
    background: $green;
    border: 1px solid $green;
    border-bottom: none;
    color: white;
  }
}

// Group Members

.dashboard-admin-approvals {
  border: 1px solid $grey-200;
  .views-row {
    padding: 1em;
    border-bottom: 1px solid $grey-200;
    position: relative;
    &:last-child {
      border-bottom: none;
    }
  }
}

.dashboard-approval-group-container {
  font-family: $fontTitle;
  margin: 0 11em 0 0;
}

h4.dashboard-approval-group-request {
  @include font-size(16px);
  font-weight: $normal;
  margin: 0 11em 0.75em 0;
  a {
    font-weight: $bold;
  }
}

.dashboard-approval-group-operations {
  margin: 1em 0 0;
  text-align: right;
  @include mq($from: tablet) {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
    margin: 0;
  }

  .links {
    list-style-type: none;
  }
  a {
    @include button;
    margin: 0 0 0.5em;
  }
}

.dashboard-approval-group-roles .item-list {
  margin: 1em 0;
}

// Org Approvals

.dashboard-admin-org-approvals .group-last-update {
  margin: 0 11em 0.5em 0;
  font-family: $fontTitle;
  @include font-size(14px);
}

.dashboard-admin-org-approvals .pub-group-status {
  margin: 0 11em 0 0;
  font-family: $fontTitle;
  @include font-size(14px);
}

// View Nutch Admin

.pane-afaas-nutch-nutch-admin .view-nutch-admin .view-header {
  padding: 1em;
  border-bottom: 1px solid $grey-200;
  p {
    margin: 0;
  }
  .crawler-button {
    @include button;
  }
}

.pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-crawler-link {
  margin: 0 11em 0.5em 0;
  font-family: $fontTitle;
}

.pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-description {
  margin: 0 11em 0.5em 0;
  @include font-size(14px);
}

.pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-edit-link {
  margin: 0 0 0.5em;
  @include mq($from: tablet) {
    position: absolute;
    right: 6.5em;
    bottom: 1em;
  }

  a {
    @include button;
  }
}

.pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-delete-link {
  margin: 0 0 0.5em;
  @include mq($from: tablet) {
    position: absolute;
    right: 1em;
    bottom: 1em;
  }

  a {
    @include button;
  }
}

// SHARED STYLES

// Public Content
// AFAAS Content
// Country Content

.dashboard-admin-public-approvals,
.dashboard-admin-afaas-approvals,
.dashboard-admin-country-approvals,
.dashboard-admin-org-approvals,
.dashboard-admin-nutch {
  border: 1px solid $grey-200;
  .views-row {
    @include clearfix;
    padding: 1em;
    border-bottom: 1px solid $grey-200;
    position: relative;
    &:last-child {
      border-bottom: none;
    }
  }
}

.dashboard-approval-container {
  font-family: $fontTitle;
  margin: 0 11em 0 0;
}

.dashboard-approval-title {
  @include font-size(16px);
}

.dashboard-approval-author,
.dashboard-approval-date,
.dashboard-approval-status {
  display: inline-block;
  margin: 0 1em 0 0;
}

.dashboard-approval-approved {
  margin: 1em 0 0;
  @include mq($from: tablet) {
    position: absolute;
    right: 1em;
    bottom: 1em;
    margin: 0;
  }

  a {
    @include button;
  }
}