/**
*
* Node - General node elements for News, Stories, etc.
*
**/

// Node image

.pane-node-field-image {
  @include mq($from: desktop) {
    float: left;
    margin: 0 2em 1em 0;
  }
}

.node-business-areas .field-image-container {
  @include mq($from: desktop) {
    float: left;
    margin: 0 2em 1em 0;
  }
}

// Node Responsive Video

.field-youtube-videos-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//  Create Content CTA styles

.create-content-links {
  margin: 0 0 2em;
}

.create-content-links a {
  @include button;
  @include font-size(14px);
  margin: 0 1em 0 0;
  &:after {
    @include icon;
    display: inline-block;
    vertical-align: middle;
    margin: -3px 0 0  7px;
    color: $bright-yellow;
    content: "u";
  }
}

//  Common Sidebar Panes
// --------------------------------

.members-pane,
.related-content-pane,
.related-group-pane,
.additional-info-pane,
.location-pane,
.sidebar-form-pane,
.search-filter-pane {
  .pane-title {
    @include font-size(18px);
    padding: 0.75em;
    margin: 0;
    background: $grey-100;
    border: 1px solid $grey-200;
    border-bottom: none;
  }
  .pane-content {
    padding: 1em;
    margin: 0 0 1.5em;
    border: 1px solid $grey-200;
  }
}

// Related Content - News Lists, etc.

.related-content-pane,
.additional-info-pane {
  .views-row {
    padding: 0.5em 0;
    border-bottom: 1px solid $grey-200;
    font-family: $fontTitle;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
}

// Forum Docs

.additional-info-pane {
  .doc-title {
    margin: 0 0 0.5em;
  }
  .doc-file a {
    color: $black;
    @include font-size(12px);
  }
}

// Members

.members-pane .views-row {
  position: relative;
  width: 31%;
  display: inline-block;
  vertical-align: top;
  margin: 0 2% 0.5em 0;
  font-family: $fontTitle;
  &:nth-child(3n) {
    margin-right: 0;
  }
  a {
    display: block;
    color: $white;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  .user-profile-name {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    padding: 4px;
    background: rgba($black, 0.5);
    display: none;
    @include font-size(13px);
    line-height: 1.2;
    a {
      height: 100%;
      width: 100%;
    }
  }
  .user-profile-label {
    color: $grey-200;
    display: block;
  }
  &:hover {
    .user-profile-name {
      display: block;
    }
  }
}

.logo-pane {
  margin: 1.5em 0 1.5em;
  border: 1px solid $grey-200;
  padding: 1em;
  text-align: center;
  img {
    display: inline-block;
    margin: 0;
    max-width: 240px;
    height: auto;
  }
  @include mq($from: desktop) {
    margin: 0 0 1.5em;
  }
}

// Map pane

.map-pane {
  padding: 1em;
  border: 1px solid $grey-200;
  margin: 0 0 1.5em;
}

.followers-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid $grey-200;
  font-family: $fontTitle;
  @include font-size(16px);
  .followers-count-label {
    font-weight: $bold;
    color: $grey-400;
  }
}

//  Subject, Keywords, Country panes

.keywords-pane {
  padding: 0 0 0.5em;
  margin: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
}

.country-pane,
.subject-pane,
.keywords-pane {
  .field-label {
    @include font-size(16px);
    font-family: $fontTitle;
    font-weight: $bold;
    color: $grey-400;
  }
}