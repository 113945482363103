/**
*
* Organization
*
**/

//  Organization View

.org-image {
  display: inline-block;
  width: 50px;
  @include mq($from: tablet) {
    width: 100px;
  }

  margin: 0 1em 0 0;
  vertical-align: top;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.view-group .org-data-container {
  display: inline-block;
  @include font-size(13px);
  width: 75%;
}

h3.org-title {
  margin: 0 0 0.5em;
  @include font-size(18px);
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
}

.org-about {
  @include font-size(13px);
  margin: 0 0 0.25em;
}

.org-meta-data-one,
.org-meta-data-two {
  font-family: $fontTitle;
}

.org-country,
.org-subject,
.org-keywords {
  font-weight: $bold;
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid $grey-400;
  a {
    color: $grey-400;
    &:hover {
      color: $green;
    }
  }
  &:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.org-country {
  text-transform: uppercase;
  a {
    color: $yellow;
  }
}

.org-meta-data-two span:last-child {
  border: none;
  padding: 0;
  margin: 0;
}

.org-joined {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid $grey-200;
}

.org-members {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid $grey-200;
}

// Organization Detail Page

.pane-organization-image-and-video-view {
  margin: 0 0 1.5em;
  img {
    width: 100%;
    height: auto;
  }
}

.org-mission-pane {
  padding: 0 0 1.5em;
  margin: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
}

.org-about-pane {
  padding: 0 0 1.5em;
  margin: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
}

.org-member-date-pane {
  padding: 0 0 1.5em;
  margin: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
}

.org-member-date-pane .views-field-created-1 {
  font-family: $fontTitle;
  @include font-size(18px);
  .views-label {
    font-weight: $bold;
  }
}

.org-site-pane {
  margin: 0 0 1em;
  font-family: $fontTitle;
  font-weight: $bold;
}

.org-linkedin-pane,
.org-facebook-pane,
.org-twitter-pane {
  font-family: $fontTitle;
  @include font-size(16px);
  display: inline-block;
  margin: 0 1em 0 0;
  padding: 0 1em 0 0;
  border-right: 1px solid $grey-200;
}

.org-facebook-pane {
  border: none;
  padding: 0;
  margin: 0;
}

//  Right Column

//  Org right blocks

.org-location-pane .field-organisation-location {
  font-family: $fontTitle;
  .country {
    font-weight: $bold;
    color: $grey-400;
  }
}