/**
*
* Node - General node elements for News, Stories, etc.
*
**/

// Node image

.pane-group-field-image,
.pane-content-video-and-image-view {
  margin: 0 0 1em;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

// Node Responsive Video

.field-youtube-videos-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//  Create Content CTA styles

.create-content-links {
  margin: 0 0 2em;
}

.create-content-links a {
  @include button;
  @include font-size(14px);
  margin: 0 1em 0 0;
  &:after {
    @include icon;
    display: inline-block;
    vertical-align: middle;
    margin: -3px 0 0  7px;
    color: $bright-yellow;
    content: "u";
  }
}