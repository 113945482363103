/**
*
* Doc
*
**/

.group-forum-doc-pane {
  margin: 0 0 1em;
}

.group-forum-doc-description-pane {
  padding: 0 0 1em;
}