/**
*
* Icon Font
* Source: http://app.fontastic.me
* Octicons Icon set
*
**/

@font-face {
  font-family: "fontastic-custom-icon-font";
  src:url("../fonts/fontastic-custom-icon-font.eot");
  src:url("../fonts/fontastic-custom-icon-font.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fontastic-custom-icon-font.woff") format("woff"),
    url("../fonts/fontastic-custom-icon-font.ttf") format("truetype"),
    url("../fonts/fontastic-custom-icon-font.svg#fontastic-custom-icon-font") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "fontastic-custom-icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontastic-custom-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-message:before {
  content: "a";
}
.icon-chevron-right:before {
  content: "b";
}
.icon-chevron-left:before {
  content: "c";
}
.icon-pencil:before {
  content: "d";
}
.icon-three-bars:before {
  content: "e";
}
.icon-location:before {
  content: "f";
}
.icon-link:before {
  content: "g";
}
.icon-bookmark:before {
  content: "h";
}
.icon-book:before {
  content: "i";
}
.icon-cloud-download:before {
  content: "j";
}
.icon-file-text:before {
  content: "k";
}
.icon-globe:before {
  content: "l";
}
.icon-info:before {
  content: "m";
}
.icon-issue-opened:before {
  content: "n";
}
.icon-gear:before {
  content: "o";
}
.icon-chevron-up:before {
  content: "p";
}
.icon-chevron-down:before {
  content: "q";
}
.icon-list-unordered:before {
  content: "r";
}
.icon-checklist:before {
  content: "s";
}
.icon-diff:before {
  content: "t";
}
.icon-diff-added:before {
  content: "u";
}
.icon-check:before {
  content: "v";
}
.icon-calendar:before {
  content: "w";
}
.icon-file-code:before {
  content: "x";
}
.icon-file-symlink-directory:before {
  content: "y";
}
.icon-file-directory:before {
  content: "z";
}
.icon-social-twitter:before {
  content: "A";
}
.icon-social-linkedin:before {
  content: "B";
}
.icon-social-facebook:before {
  content: "C";
}
.icon-social-google-plus:before {
  content: "D";
}
.icon-heart:before {
  content: "E";
}
.icon-eye:before {
  content: "F";
}
.icon-home:before {
  content: "G";
}
.icon-mail:before {
  content: "H";
}
.icon-key:before {
  content: "I";
}
.icon-search:before {
  content: "J";
}
.icon-like-filled:before {
  content: "K";
}
.icon-star:before {
  content: "L";
}
.icon-plus:before {
  content: "M";
}
.icon-person:before {
  content: "N";
}
.icon-file-media:before {
  content: "O";
}
.icon-question:before {
  content: "P";
}
.icon-question-1:before {
  content: "Q";
}