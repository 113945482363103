/**
*
* Imports
*
**/
/**
*
* Variables
*
**/
/**
*
* Functions
*
**/
/**
*
* Mixins
*
**/
/**
*
* Helpers
*
**/
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: none; }

.inline {
  display: inline; }

/**
*
* Icon Font
* Source: http://app.fontastic.me
* Octicons Icon set
*
**/
@font-face {
  font-family: "fontastic-custom-icon-font";
  src: url("../fonts/fontastic-custom-icon-font.eot");
  src: url("../fonts/fontastic-custom-icon-font.eot?#iefix") format("embedded-opentype"), url("../fonts/fontastic-custom-icon-font.woff") format("woff"), url("../fonts/fontastic-custom-icon-font.ttf") format("truetype"), url("../fonts/fontastic-custom-icon-font.svg#fontastic-custom-icon-font") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "fontastic-custom-icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontastic-custom-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-message:before {
  content: "a"; }

.icon-chevron-right:before {
  content: "b"; }

.icon-chevron-left:before {
  content: "c"; }

.icon-pencil:before {
  content: "d"; }

.icon-three-bars:before {
  content: "e"; }

.icon-location:before {
  content: "f"; }

.icon-link:before {
  content: "g"; }

.icon-bookmark:before {
  content: "h"; }

.icon-book:before {
  content: "i"; }

.icon-cloud-download:before {
  content: "j"; }

.icon-file-text:before {
  content: "k"; }

.icon-globe:before {
  content: "l"; }

.icon-info:before {
  content: "m"; }

.icon-issue-opened:before {
  content: "n"; }

.icon-gear:before {
  content: "o"; }

.icon-chevron-up:before {
  content: "p"; }

.icon-chevron-down:before {
  content: "q"; }

.icon-list-unordered:before {
  content: "r"; }

.icon-checklist:before {
  content: "s"; }

.icon-diff:before {
  content: "t"; }

.icon-diff-added:before {
  content: "u"; }

.icon-check:before {
  content: "v"; }

.icon-calendar:before {
  content: "w"; }

.icon-file-code:before {
  content: "x"; }

.icon-file-symlink-directory:before {
  content: "y"; }

.icon-file-directory:before {
  content: "z"; }

.icon-social-twitter:before {
  content: "A"; }

.icon-social-linkedin:before {
  content: "B"; }

.icon-social-facebook:before {
  content: "C"; }

.icon-social-google-plus:before {
  content: "D"; }

.icon-heart:before {
  content: "E"; }

.icon-eye:before {
  content: "F"; }

.icon-home:before {
  content: "G"; }

.icon-mail:before {
  content: "H"; }

.icon-key:before {
  content: "I"; }

.icon-search:before {
  content: "J"; }

.icon-like-filled:before {
  content: "K"; }

.icon-star:before {
  content: "L"; }

.icon-plus:before {
  content: "M"; }

.icon-person:before {
  content: "N"; }

.icon-file-media:before {
  content: "O"; }

.icon-question:before {
  content: "P"; }

.icon-question-1:before {
  content: "Q"; }

/**
*
* Reset
*
**/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

a img {
  border: none; }

blockquote {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

/**
*
* Global
*
**/
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

::selection {
  background: #f0b03b;
  color: #fff; }

/**
*
* Typography
*
**/
body {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #555;
  font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  line-height: 1;
  color: #555;
  font-weight: 700; }

.main-content h2, .main-content h3, .main-content h4, .main-content h5, .main-content h6,
.main-content blockquote, .main-content p, .main-content address {
  margin: 0 0 0.75em; }

h1 {
  font-size: 30px;
  font-size: 1.875rem;
  margin: 0 0 0.75em; }

h2 {
  font-size: 26px;
  font-size: 1.625rem; }

h3 {
  font-size: 20px;
  font-size: 1.25rem; }

h4 {
  font-size: 18px;
  font-size: 1.125rem; }

h5 {
  font-size: 16px;
  font-size: 1rem; }

h6 {
  font-size: 14px;
  font-size: 0.875rem; }

strong {
  font-weight: 700; }

em {
  font-style: italic; }

blockquote {
  display: block;
  padding: 1em 3em 1em 2.5em;
  position: relative;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  font-style: italic; }
  blockquote:before {
    content: "\201C";
    font-size: 60px;
    font-size: 3.75rem;
    font-weight: 700;
    color: #a2a2a2;
    font-style: normal;
    position: absolute;
    left: 0;
    top: 5px; }
  blockquote::after {
    content: ""; }

/**
*
* Main Nav
*
**/
.main-nav {
  background: #f7f7f7;
  border-bottom: 1px solid #eaeaea; }

.block-system-main-menu {
  display: none; }
  @media (min-width: 46.25em) {
    .block-system-main-menu {
      display: block; } }

.block-system-main-menu.expand {
  display: block; }

.mobile-menu-btn {
  display: block;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  text-indent: -999em; }
  .mobile-menu-btn:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0.8em;
    width: 2em;
    height: 0.30em;
    background: #444;
    box-shadow: 0 0.5em 0 0 #444, 0 1em 0 0 #444; }
  @media (min-width: 46.25em) {
    .mobile-menu-btn {
      display: none; } }

.block-system-main-menu .menu li {
  list-style-type: none;
  border-bottom: 1px solid #eaeaea; }
  @media (min-width: 46.25em) {
    .block-system-main-menu .menu li {
      display: inline-block;
      border-right: 1px solid #eaeaea;
      border: none; } }
  .block-system-main-menu .menu li:first-child {
    border-top: 1px solid #eaeaea; }
  .block-system-main-menu .menu li:last-child {
    border: none; }

.block-system-main-menu .menu li a {
  display: block;
  padding: 10px 0;
  text-transform: uppercase;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1; }
  .block-system-main-menu .menu li a:hover, .block-system-main-menu .menu li a.active {
    color: #003622; }
  @media (min-width: 46.25em) {
    .block-system-main-menu .menu li a {
      font-size: 14px;
      font-size: 0.875rem;
      padding: 12px 15px; } }
  @media (min-width: 61.25em) {
    .block-system-main-menu .menu li a {
      padding: 15px 20px;
      font-size: 15px;
      font-size: 0.9375rem; } }

/**
*
* Lists
*
**/
ul, ol {
  list-style-position: inside; }

ul {
  list-style-type: disc; }

.region-content ul, .region-content ol {
  margin: 0 0 1em 2em; }

.links.inline {
  list-style-type: none; }

/**
*
* Links
*
**/
a {
  color: #0d6839;
  transition: .5s;
  text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #020d07; }
  a:visited {
    text-decoration: none;
    outline: 0; }

/**
*
* Tables
*
**/
table {
  width: 100%;
  background: #fff;
  margin: 0 0 2em; }

th, td {
  border: 1px solid #e0e0e0;
  padding: 0.5em;
  font-size: 12px;
  font-size: 0.75rem; }

th {
  background: #f7f7f7; }

/**
*
* Forms
*
**/
.main-content input[type="text"],
.main-content input[type="email"],
.main-content input[type="password"],
.main-content textarea {
  width: 100%;
  display: block;
  background: none;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  padding: 9px;
  appearance: none;
  box-shadow: none;
  transition: border 0.3s;
  font-size: 12px;
  font-size: 0.75rem;
  color: #0f405d;
  font-family: "Open Sans", Helvetica, Arial, sans-serif; }

.main-content input[type="submit"] {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 9px 20px;
  margin: 0 10px 1em 0;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  transition: all 0.3s; }
  .main-content input[type="submit"]:hover {
    background: #f0b03b;
    color: #fff; }

.main-content input[type="text"]:focus,
.main-content input[type="email"]:focus,
.main-content input[type="password"]:focus,
.main-content select:focus,
.main-content textarea:focus {
  outline: none;
  border-color: #cacaca; }

.main-content input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset; }

.main-content input[type="checkbox"] {
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: middle; }

.main-content select {
  display: block;
  width: 100%;
  height: 40px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  color: #747474;
  transition: border 0.3s; }

.main-content input.error[type="radio"] {
  outline: 1px solid #ff0000; }

.main-content input.error[type="radio"]:checked {
  outline: none; }

.not-front .form-item {
  display: block;
  margin: 0 0 1em;
  font-size: 13px;
  font-size: 0.8125rem; }
  .not-front .form-item label {
    display: block;
    margin: 0 0 0.25em; }

.main-content .form-required {
  color: #ff0000; }

/**
*
* Drupal Overrides
*
**/
.front .message-dialog {
  display: none; }

.message-dialog {
  margin: 0 0 2em;
  word-wrap: break-word;
  font-size: 12px;
  font-size: 0.75rem; }
  .message-dialog.messages--status {
    border-color: #0f405d; }
  .message-dialog .messages {
    margin: 0 0 1.5em; }

.action-links {
  margin: 0 0 1em; }

.action-links li {
  display: inline-block;
  list-style-type: none; }

.contextual-links-region:before, .contextual-links-region:after {
  content: "";
  display: table; }

.contextual-links-region:after {
  clear: both; }

.filter-wrapper {
  margin: 0 0 1em; }

.tips {
  font-size: 12px;
  font-size: 0.75rem; }

.submitted {
  margin: 0 0 1em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

html.js fieldset.collapsed {
  height: 2em; }
  html.js fieldset.collapsed:before, html.js fieldset.collapsed:after {
    content: "";
    display: table; }
  html.js fieldset.collapsed:after {
    clear: both; }

/**
*
* Animations
*
**/
@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/**
*
* Action links
*
**/
.action-links {
  padding: 1em 0 0 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }
  .action-links a {
    display: block;
    padding: 5px 10px;
    margin: 0 1em 1em 0;
    background: #f7f7f7;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
    .action-links a:hover, .action-links a.active {
      background: #0d6839;
      color: #fff; }
  .action-links:last-child a {
    margin-right: 0; }

/**
*
* Tags
*
**/
.tags li {
  display: inline; }
  .tags li a {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 1em 1em 0;
    background: #f7f7f7;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
    .tags li a:hover, .tags li a.active {
      background: #a2a2a2;
      color: #fff; }
  .tags li:last-child a {
    margin-right: 0; }

/**
*
* Files
*
**/
.field-upload-files {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

/**
*
* Sharethis buttons
*
**/
.pane-node-sharethis {
  margin: 2em 0 1em; }

.pane-node-sharethis h2 {
  font-size: 20px;
  font-size: 1.25rem;
  margin: 0 0 0.25em; }

.sharethis-buttons {
  padding: 0.5em 0;
  height: 43px; }

.stButton span.stLarge {
  background-size: 24px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 0 0.5em 0 0;
  border-radius: 4px;
  overflow: hidden; }
  .stButton span.stLarge:hover {
    background-position: 0 0; }

/**
*
* Tabs
*
**/
.tabs-container {
  margin: 0  0 1em; }

.tabs li {
  display: inline-block; }
  .tabs li a {
    display: block;
    padding: 5px 10px;
    margin: 0 1em 1em 0;
    background: #f7f7f7;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
    .tabs li a:hover, .tabs li a.active {
      background: #0d6839;
      color: #fff; }
  .tabs li:last-child a {
    margin-right: 0; }

.ui-tabs-nav {
  margin: 0 0 1em; }

.ui-tabs-nav li {
  display: inline-block; }
  .ui-tabs-nav li a {
    display: block;
    padding: 8px 16px;
    margin: 0 1em 1em 0;
    background: #0d6839;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff; }
    .ui-tabs-nav li a:hover, .ui-tabs-nav li a.active {
      background: #f0b03b;
      color: #fff; }
  .ui-tabs-nav li:last-child a {
    margin-right: 0; }
  .ui-tabs-nav li.ui-state-focus a {
    outline: 0;
    background: #f0b03b;
    color: #fff; }

/**
*
* Filters
*
**/
.view-people .views-exposed-form,
.view-groups .views-exposed-form,
.view-problem-solve .views-exposed-form,
.view-resources .views-exposed-form,
.view-opps .views-exposed-form,
.view-stories .views-exposed-form,
.view-news .views-exposed-form,
.view-orgs .views-exposed-form {
  padding: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

@media (min-width: 61.25em) {
  .view-people .views-exposed-widget,
  .view-groups .views-exposed-widget,
  .view-problem-solve .views-exposed-widget,
  .view-resources .views-exposed-widget,
  .view-opps .views-exposed-widget,
  .view-stories .views-exposed-widget,
  .view-news .views-exposed-widget,
  .view-orgs .views-exposed-widget {
    margin: 0 10px 0 0; } }

@media (min-width: 61.25em) {
  .view-people .views-exposed-widget,
  .view-groups .views-exposed-widget,
  .view-problem-solve .views-exposed-widget,
  .view-resources .views-exposed-widget,
  .view-opps .views-exposed-widget,
  .view-stories .views-exposed-widget,
  .view-news .views-exposed-widget,
  .view-orgs .views-exposed-widget {
    float: left;
    width: 17%; } }

.view-people .views-exposed-widget label,
.view-groups .views-exposed-widget label,
.view-problem-solve .views-exposed-widget label,
.view-resources .views-exposed-widget label,
.view-opps .views-exposed-widget label,
.view-stories .views-exposed-widget label,
.view-news .views-exposed-widget label,
.view-orgs .views-exposed-widget label {
  font-size: 12px;
  font-size: 0.75rem; }

@media (min-width: 61.25em) {
  .main-content-left .view-people .views-exposed-widget, .main-content-left
  .view-groups .views-exposed-widget, .main-content-left
  .view-problem-solve .views-exposed-widget, .main-content-left
  .view-resources .views-exposed-widget, .main-content-left
  .view-opps .views-exposed-widget, .main-content-left
  .view-stories .views-exposed-widget, .main-content-left
  .view-news .views-exposed-widget, .main-content-left
  .view-orgs .views-exposed-widget {
    float: left;
    width: 30%; } }

.view-people .views-exposed-widget.views-submit-button,
.view-people .views-exposed-widget.views-reset-button,
.view-groups .views-exposed-widget.views-submit-button,
.view-groups .views-exposed-widget.views-reset-button,
.view-problem-solve .views-exposed-widget.views-submit-button,
.view-problem-solve .views-exposed-widget.views-reset-button,
.view-resources .views-exposed-widget.views-submit-button,
.view-resources .views-exposed-widget.views-reset-button,
.view-opps .views-exposed-widget.views-submit-button,
.view-opps .views-exposed-widget.views-reset-button,
.view-stories .views-exposed-widget.views-submit-button,
.view-stories .views-exposed-widget.views-reset-button,
.view-news .views-exposed-widget.views-submit-button,
.view-news .views-exposed-widget.views-reset-button,
.view-orgs .views-exposed-widget.views-submit-button,
.view-orgs .views-exposed-widget.views-reset-button {
  display: inline-block;
  margin: 0 10px 0 0; }
  @media (min-width: 61.25em) {
    .view-people .views-exposed-widget.views-submit-button,
    .view-people .views-exposed-widget.views-reset-button,
    .view-groups .views-exposed-widget.views-submit-button,
    .view-groups .views-exposed-widget.views-reset-button,
    .view-problem-solve .views-exposed-widget.views-submit-button,
    .view-problem-solve .views-exposed-widget.views-reset-button,
    .view-resources .views-exposed-widget.views-submit-button,
    .view-resources .views-exposed-widget.views-reset-button,
    .view-opps .views-exposed-widget.views-submit-button,
    .view-opps .views-exposed-widget.views-reset-button,
    .view-stories .views-exposed-widget.views-submit-button,
    .view-stories .views-exposed-widget.views-reset-button,
    .view-news .views-exposed-widget.views-submit-button,
    .view-news .views-exposed-widget.views-reset-button,
    .view-orgs .views-exposed-widget.views-submit-button,
    .view-orgs .views-exposed-widget.views-reset-button {
      margin: 1.5em 10px 0 0;
      width: auto; } }
  .view-people .views-exposed-widget.views-submit-button input[type="submit"],
  .view-people .views-exposed-widget.views-reset-button input[type="submit"],
  .view-groups .views-exposed-widget.views-submit-button input[type="submit"],
  .view-groups .views-exposed-widget.views-reset-button input[type="submit"],
  .view-problem-solve .views-exposed-widget.views-submit-button input[type="submit"],
  .view-problem-solve .views-exposed-widget.views-reset-button input[type="submit"],
  .view-resources .views-exposed-widget.views-submit-button input[type="submit"],
  .view-resources .views-exposed-widget.views-reset-button input[type="submit"],
  .view-opps .views-exposed-widget.views-submit-button input[type="submit"],
  .view-opps .views-exposed-widget.views-reset-button input[type="submit"],
  .view-stories .views-exposed-widget.views-submit-button input[type="submit"],
  .view-stories .views-exposed-widget.views-reset-button input[type="submit"],
  .view-news .views-exposed-widget.views-submit-button input[type="submit"],
  .view-news .views-exposed-widget.views-reset-button input[type="submit"],
  .view-orgs .views-exposed-widget.views-submit-button input[type="submit"],
  .view-orgs .views-exposed-widget.views-reset-button input[type="submit"] {
    margin: 0; }

.view-people .views-exposed-widget.views-reset-button input[type="submit"],
.view-groups .views-exposed-widget.views-reset-button input[type="submit"],
.view-problem-solve .views-exposed-widget.views-reset-button input[type="submit"],
.view-resources .views-exposed-widget.views-reset-button input[type="submit"],
.view-opps .views-exposed-widget.views-reset-button input[type="submit"],
.view-stories .views-exposed-widget.views-reset-button input[type="submit"],
.view-news .views-exposed-widget.views-reset-button input[type="submit"],
.view-orgs .views-exposed-widget.views-reset-button input[type="submit"] {
  background: #cacaca; }
  .view-people .views-exposed-widget.views-reset-button input[type="submit"]:hover,
  .view-groups .views-exposed-widget.views-reset-button input[type="submit"]:hover,
  .view-problem-solve .views-exposed-widget.views-reset-button input[type="submit"]:hover,
  .view-resources .views-exposed-widget.views-reset-button input[type="submit"]:hover,
  .view-opps .views-exposed-widget.views-reset-button input[type="submit"]:hover,
  .view-stories .views-exposed-widget.views-reset-button input[type="submit"]:hover,
  .view-news .views-exposed-widget.views-reset-button input[type="submit"]:hover,
  .view-orgs .views-exposed-widget.views-reset-button input[type="submit"]:hover {
    background: #f0b03b; }

.chosen-container {
  font-size: 12px;
  font-size: 0.75rem;
  color: #747474; }

.chosen-container-single .chosen-single {
  height: 37px;
  line-height: 37px;
  border-color: #e0e0e0;
  background: #fff;
  border-radius: 0;
  box-shadow: none;
  color: #747474; }

.chosen-container-single .chosen-single div {
  top: 6px; }

.chosen-container-active.chosen-with-drop .chosen-single {
  border-color: #cacaca;
  box-shadow: none;
  background-image: none; }

.chosen-container .chosen-drop {
  border-radius: 0;
  box-shadow: none;
  border-color: #cacaca; }

.chosen-container .chosen-results li.highlighted {
  box-shadow: none;
  background-image: none;
  background: #0d6839; }

.chosen-container-multi .chosen-choices {
  box-shadow: none;
  background-image: none;
  border-color: #e0e0e0;
  padding: 3px 0;
  margin: 0; }

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  font-family: "Open Sans", Helvetica, Arial, sans-serif; }

/**
*
* Pager
*
**/
.pager {
  padding: 1.5em 0;
  border-top: 1px solid #e0e0e0;
  text-align: center; }

.sidebar-right .pager {
  margin: 0.5em 0 0 0;
  padding: 1em 0 0 0;
  border-top: 1px solid #e0e0e0; }

.pager li {
  display: inline-block;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .pager li a {
    display: inline-block; }

.pager-current {
  padding: 0 0.5em; }

.pager-item a {
  padding: 0 0.5em; }

.pager-next,
.pager-last {
  padding: 0 0.25em; }

.pager-current {
  color: #f0b03b; }

/**
*
* Node - General node elements for News, Stories, etc.
*
**/
@media (min-width: 61.25em) {
  .pane-node-field-image {
    float: left;
    margin: 0 2em 1em 0; } }

@media (min-width: 61.25em) {
  .node-business-areas .field-image-container {
    float: left;
    margin: 0 2em 1em 0; } }

.field-youtube-videos-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }
  .field-youtube-videos-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.create-content-links {
  margin: 0 0 2em; }

.create-content-links a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0 1em 0 0; }
  .create-content-links a:hover {
    background: #f0b03b;
    color: #fff; }
  .create-content-links a:after {
    font-family: "fontastic-custom-icon-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
    margin: -3px 0 0  7px;
    color: #ffcc00;
    content: "u"; }

.members-pane .pane-title,
.related-content-pane .pane-title,
.related-group-pane .pane-title,
.additional-info-pane .pane-title,
.location-pane .pane-title,
.sidebar-form-pane .pane-title,
.search-filter-pane .pane-title {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0.75em;
  margin: 0;
  background: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-bottom: none; }

.members-pane .pane-content,
.related-content-pane .pane-content,
.related-group-pane .pane-content,
.additional-info-pane .pane-content,
.location-pane .pane-content,
.sidebar-form-pane .pane-content,
.search-filter-pane .pane-content {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0; }

.related-content-pane .views-row,
.additional-info-pane .views-row {
  padding: 0.5em 0;
  border-bottom: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .related-content-pane .views-row:first-child,
  .additional-info-pane .views-row:first-child {
    padding-top: 0; }
  .related-content-pane .views-row:last-child,
  .additional-info-pane .views-row:last-child {
    border: none;
    padding-bottom: 0; }

.additional-info-pane .doc-title {
  margin: 0 0 0.5em; }

.additional-info-pane .doc-file a {
  color: #000;
  font-size: 12px;
  font-size: 0.75rem; }

.members-pane .views-row {
  position: relative;
  width: 31%;
  display: inline-block;
  vertical-align: top;
  margin: 0 2% 0.5em 0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .members-pane .views-row:nth-child(3n) {
    margin-right: 0; }
  .members-pane .views-row a {
    display: block;
    color: #fff; }
  .members-pane .views-row img {
    width: 100%;
    height: auto;
    display: block; }
  .members-pane .views-row .user-profile-name {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    padding: 4px;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.2; }
    .members-pane .views-row .user-profile-name a {
      height: 100%;
      width: 100%; }
  .members-pane .views-row .user-profile-label {
    color: #e0e0e0;
    display: block; }
  .members-pane .views-row:hover .user-profile-name {
    display: block; }

.logo-pane {
  margin: 1.5em 0 1.5em;
  border: 1px solid #e0e0e0;
  padding: 1em;
  text-align: center; }
  .logo-pane img {
    display: inline-block;
    margin: 0;
    max-width: 240px;
    height: auto; }
  @media (min-width: 61.25em) {
    .logo-pane {
      margin: 0 0 1.5em; } }

.map-pane {
  padding: 1em;
  border: 1px solid #e0e0e0;
  margin: 0 0 1.5em; }

.followers-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem; }
  .followers-pane .followers-count-label {
    font-weight: 700;
    color: #a2a2a2; }

.keywords-pane {
  padding: 0 0 0.5em;
  margin: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

.country-pane .field-label,
.subject-pane .field-label,
.keywords-pane .field-label {
  font-size: 16px;
  font-size: 1rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #a2a2a2; }

/**
*
* Node - General node elements for News, Stories, etc.
*
**/
.pane-group-field-image,
.pane-content-video-and-image-view {
  margin: 0 0 1em; }
  .pane-group-field-image img,
  .pane-content-video-and-image-view img {
    display: block;
    width: 100%;
    height: auto; }

.field-youtube-videos-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }
  .field-youtube-videos-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.create-content-links {
  margin: 0 0 2em; }

.create-content-links a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0 1em 0 0; }
  .create-content-links a:hover {
    background: #f0b03b;
    color: #fff; }
  .create-content-links a:after {
    font-family: "fontastic-custom-icon-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
    margin: -3px 0 0  7px;
    color: #ffcc00;
    content: "u"; }

/**
*
* Comments
*
**/
.comment-wrapper {
  padding: 1.5em 0 0 0;
  border-top: 1px solid #e0e0e0; }

.comment {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

.comment-content {
  margin: 0 0 1em; }

.comment-add {
  margin: 0 0  1.5em; }

.pane-group-field-comments {
  padding: 0 0 1.5em; }

.comment .links li {
  display: inline;
  list-style-type: none; }

.comment-wrapper .ckeditor_links {
  float: right; }

.comment-wrapper .unauth_notice {
  display: block;
  padding: 1em 0; }

.indented {
  padding-left: 1em; }

.comment {
  padding: 1.5em 0; }

.comment .form-type-textarea label {
  display: none; }

.comment-details {
  margin: 0 0 1em; }
  .comment-details:before, .comment-details:after {
    content: "";
    display: table; }
  .comment-details:after {
    clear: both; }

.comment .user-details {
  float: left; }

.comment .user-image {
  width: 60px; }
  .comment .user-image img {
    width: 100%;
    height: auto; }

.comment .user-name {
  font-size: 13px;
  font-size: 0.8125rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.comment .comment-date {
  float: right;
  line-height: 40px;
  vertical-align: top;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.statistics_counter {
  margin: 0 0 1.5em;
  font-size: 13px;
  font-size: 0.8125rem; }

.comment .new {
  float: right;
  margin: 0.5em 0 0 1em;
  padding: 0.35em 0.5em;
  line-height: 1;
  border-radius: 2px;
  background: #f0b03b;
  color: white;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.flag-send_to_questioner_by_sms .unflag-disabled {
  float: right;
  margin: 0.5em 0 0 1em;
  padding: 0.35em 0.5em;
  line-height: 1;
  border-radius: 2px;
  background: #f0b03b;
  color: white;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.comment .field-comment-body-container {
  padding: 1em 0; }

h2.comment-form {
  border-top: 1px solid #e0e0e0;
  padding: 0.75em 0 0 0;
  margin: 0.5em 0 0.5em 0; }

.comment-form .form-item {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem; }
  .comment-form .form-item label {
    display: inline; }

/**
*
* Buttons
*
**/
.flag-like a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 400; }
  .flag-like a:hover {
    background: #f0b03b;
    color: #fff; }
  .flag-like a:after {
    font-family: "fontastic-custom-icon-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "h";
    font-size: 1.5em;
    line-height: 1em;
    vertical-align: bottom;
    letter-spacing: -0.25em;
    display: inline-block;
    margin: 0  0 0 0.5em;
    color: #ffcc00; }
  .flag-like a:hover:after {
    color: #fff; }

.flag-follow-sms a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 400; }
  .flag-follow-sms a:hover {
    background: #f0b03b;
    color: #fff; }
  .flag-follow-sms a:after {
    font-family: "fontastic-custom-icon-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "a";
    line-height: 1.25em;
    vertical-align: middle;
    display: inline-block;
    margin: 0  0 0 0.5em;
    color: #ffcc00; }
  .flag-follow-sms a:hover:after {
    color: #fff; }

.flag-follow-email a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 400; }
  .flag-follow-email a:hover {
    background: #f0b03b;
    color: #fff; }
  .flag-follow-email a:after {
    font-family: "fontastic-custom-icon-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "H";
    line-height: 1.25em;
    vertical-align: middle;
    display: inline-block;
    margin: 0  0 0 0.5em;
    color: #ffcc00; }
  .flag-follow-email a:hover:after {
    color: #fff; }

.flag-reject a,
.flag-attention a,
.flag-close a,
.flag-send-to-assignees-by-sms a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 400; }
  .flag-reject a:hover,
  .flag-attention a:hover,
  .flag-close a:hover,
  .flag-send-to-assignees-by-sms a:hover {
    background: #f0b03b;
    color: #fff; }

.flag-follow-group a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 400; }
  .flag-follow-group a:hover {
    background: #f0b03b;
    color: #fff; }
  .flag-follow-group a:hover:after {
    color: #fff; }

.comment-delete a,
.comment-edit a,
.comment-reply a,
.comment-add > a,
.flag-reject_comment a,
.flag-send_to_questioner_by_sms a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 400; }
  .comment-delete a:hover,
  .comment-edit a:hover,
  .comment-reply a:hover,
  .comment-add > a:hover,
  .flag-reject_comment a:hover,
  .flag-send_to_questioner_by_sms a:hover {
    background: #f0b03b;
    color: #fff; }

.node-type-document .action-links a {
  background: #0d6839;
  color: #fff; }
  .node-type-document .action-links a:hover {
    background: #e0e0e0;
    color: #0d6839; }

/**
*
* Layout
*
**/
.contain {
  padding: 0 1em; }
  .contain:before, .contain:after {
    content: "";
    display: table; }
  .contain:after {
    clear: both; }
  @media (min-width: 46.25em) {
    .contain {
      width: 80%;
      margin: 0 auto;
      padding: 0; } }

.not-front .main-content {
  padding: 1em; }
  .not-front .main-content:before, .not-front .main-content:after {
    content: "";
    display: table; }
  .not-front .main-content:after {
    clear: both; }
  @media (min-width: 46.25em) {
    .not-front .main-content {
      width: 80%;
      margin: 0 auto;
      padding: 2em 0; } }

.main-content-left:before, .main-content-left:after {
  content: "";
  display: table; }

.main-content-left:after {
  clear: both; }

@media (min-width: 61.25em) {
  .main-content-left {
    width: 65%;
    float: left;
    margin: 0 2em 0 0; } }

@media (min-width: 61.25em) {
  .sidebar-right {
    width: 30%;
    float: left; } }

@media (min-width: 61.25em) {
  .main-content-right {
    width: 65%;
    float: left; } }

@media (min-width: 61.25em) {
  .sidebar-left {
    width: 30%;
    float: left;
    margin: 0 2em 0 0; } }

/**
*
* Header
*
**/
.header-main {
  padding: 1em 0;
  background: #0d6839;
  border-bottom: 7px solid #f0b03b; }

.logo {
  display: block;
  margin: 0 0 1em;
  float: none;
  width: 240px; }
  @media (min-width: 46.25em) {
    .logo {
      float: left;
      margin: 0 1em 1em 0; } }
  @media (min-width: 61.25em) {
    .logo {
      margin: 0; } }
  .logo img {
    display: block;
    width: 100%; }

.block-system-user-menu {
  width: 100%;
  clear: both;
  text-align: left; }
  @media (min-width: 61.25em) {
    .block-system-user-menu {
      width: 68%;
      clear: none;
      float: right;
      text-align: right; } }

.block-system-user-menu .menu {
  list-style-type: none; }
  .block-system-user-menu .menu li {
    display: inline-block;
    margin: 0 .25em 0 0;
    padding: 0 .5em 0 0;
    border-right: 1px solid #82957a;
    line-height: 1; }
    .block-system-user-menu .menu li:last-child {
      padding: 0;
      border: none; }
  .block-system-user-menu .menu a {
    color: #fff;
    font-size: 13px;
    font-size: 0.8125rem;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
    .block-system-user-menu .menu a:hover {
      color: #ffcc00; }
  .block-system-user-menu .menu .msg-link:after,
  .block-system-user-menu .menu .create-content-link:after,
  .block-system-user-menu .menu .dashbord-link:after,
  .block-system-user-menu .menu .ask-a-question-link:after,
  .block-system-user-menu .menu .help-centre-link:after,
  .block-system-user-menu .menu .admin-people-link:after {
    font-family: "fontastic-custom-icon-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0  5px;
    color: #ffcc00; }
  .block-system-user-menu .menu .msg-link:after {
    content: "a"; }
  .block-system-user-menu .menu .create-content-link:after {
    content: "u"; }
  .block-system-user-menu .menu .dashbord-link:after {
    content: "k"; }
  .block-system-user-menu .menu .ask-a-question-link:after {
    content: "Q"; }
  .block-system-user-menu .menu .help-centre-link:after {
    content: "m"; }
  .block-system-user-menu .menu .admin-people-link:after {
    content: "N"; }

.block-menu-menu-log-in-out {
  margin: 0 0 0.5em 0; }
  @media (min-width: 46.25em) {
    .block-menu-menu-log-in-out {
      text-align: right;
      width: 30%;
      float: right;
      margin: 0.25em 1em 1em 0; } }

.block-menu-menu-log-in-out .menu {
  list-style-type: none; }
  .block-menu-menu-log-in-out .menu li {
    display: inline-block;
    margin: 0 .5em 0 0;
    padding: 0 .5em 0 0;
    border-right: 1px solid #82957a;
    line-height: 1; }
    .block-menu-menu-log-in-out .menu li:last-child {
      padding: 0;
      border: none; }
  .block-menu-menu-log-in-out .menu a {
    color: #fff;
    font-size: 13px;
    font-size: 0.8125rem;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
    .block-menu-menu-log-in-out .menu a:hover {
      color: #ffcc00; }

.not-logged-in .block-menu-menu-log-in-out {
  margin: 0 1em 0 0; }
  .not-logged-in .block-menu-menu-log-in-out a {
    padding: 9px 1em;
    background: #f0b03b;
    display: inline-block; }
    .not-logged-in .block-menu-menu-log-in-out a:hover {
      background: #11884a;
      color: #fff; }

.block-search-form {
  margin: 0 0 .75em; }
  @media (min-width: 46.25em) {
    .block-search-form {
      width: 200px;
      float: right; } }

.block-search-form .form-item-search-block-form {
  display: block;
  width: 140px;
  float: left; }

.block-search-form .form-text {
  width: 100%;
  background: none;
  border: none;
  background-color: #fff;
  padding: 7px;
  appearance: none;
  box-shadow: none;
  transition: border 0.3s;
  font-size: 12px;
  font-size: 0.75rem;
  color: #0d6839;
  font-family: "Open Sans", Helvetica, Arial, sans-serif; }
  .block-search-form .form-text:focus {
    outline: none; }

.block-search-form .form-submit {
  display: inline-block;
  border: none;
  background: #11884a;
  padding: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  line-height: .95;
  transition: all 0.3s; }
  .block-search-form .form-submit:hover {
    background: #139552;
    color: #fff; }

/**
*
* Footer
*
**/
.footer-main {
  padding: 2em  0;
  background: #f7f7f7;
  border-top: 1px solid #eaeaea; }

.block-menu-menu-footer-menu {
  margin: 0.5em 0 1em; }
  @media (min-width: 61.25em) {
    .block-menu-menu-footer-menu {
      width: 45%;
      float: left; } }

.block-menu-menu-footer-menu .menu {
  list-style-type: none; }
  .block-menu-menu-footer-menu .menu li {
    display: inline-block;
    margin: 0 .5em 0 0;
    padding: 0 .5em 0 0;
    border-right: 1px solid #e0e0e0;
    line-height: 1; }
    .block-menu-menu-footer-menu .menu li:last-child {
      padding: 0;
      border: none; }
  .block-menu-menu-footer-menu .menu a {
    color: #0d6839;
    font-size: 15px;
    font-size: 0.9375rem;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
    .block-menu-menu-footer-menu .menu a:hover {
      color: #000; }

.block-social-media-links {
  margin: 0 0 1em; }
  @media (min-width: 61.25em) {
    .block-social-media-links {
      float: right;
      text-align: right; } }

.social-media-links li {
  display: inline-block;
  margin: 0 .5em 0 0; }
  .social-media-links li:last-child {
    margin: 0; }

.social-media-links a {
  display: inline-block; }
  .social-media-links a:hover {
    opacity: 0.75; }

.social-media-links img {
  display: inline-block;
  width: 32px;
  height: 32px; }

.block-views-partners-block {
  clear: both;
  padding: 2em 0;
  border-top: 1px solid #e0e0e0; }
  .block-views-partners-block:before, .block-views-partners-block:after {
    content: "";
    display: table; }
  .block-views-partners-block:after {
    clear: both; }

.block-views-partners-block h2 {
  margin: 0 0  1em;
  font-size: 18px;
  font-size: 1.125rem;
  color: #848484;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400; }

.block-views-partners-block .views-row {
  display: inline-block;
  margin: 0 2em 0 0; }
  .block-views-partners-block .views-row:last-child {
    margin: 0; }
  .block-views-partners-block .views-row a {
    display: block;
    opacity: 0.7; }
    .block-views-partners-block .views-row a:hover {
      opacity: 1; }
  .block-views-partners-block .views-row img {
    display: block;
    max-width: 100px; }

.copyright {
  padding: 1em 0 0;
  border-top: 1px solid #e0e0e0;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #848484;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

/**
*
*  Search Results
*
**/
.pane-apachesolr-form {
  margin: 0 0 1em; }
  .pane-apachesolr-form .form-type-textfield {
    display: inline-block;
    width: 70%; }
  .pane-apachesolr-form .form-actions {
    display: inline-block;
    width: 20%; }

.pane-apachesolr-result {
  margin: 0 0 2em; }

.search-results {
  list-style: none; }

.search-result {
  padding: 1.5em 0;
  border-bottom: 1px solid #e0e0e0; }
  .search-result:first-child {
    border-top: 1px solid #e0e0e0; }
  .search-result:last-child {
    border-bottom: none; }

.search-partner-result {
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: -0.5em 0 0.5em;
  text-transform: uppercase;
  color: #a2a2a2; }

.search-result .title {
  margin: 0 0 0.5em; }
  .search-result .title .title-label,
  .search-result .title .title-external-label {
    font-weight: 400; }
  .search-result .title .certification {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.search-result-url {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  margin: 0 0 0.25em; }

.search-snippet-url {
  display: block;
  margin: 0 0 0.5em; }

.search-result-content-container {
  font-size: 13px;
  font-size: 0.8125rem; }

.search-snippet a.read-more {
  font-weight: 700;
  margin: 0 0 0 0.5em; }

.search-snippet:last-child {
  margin: 0; }

.search-result-meta-data-one {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .search-result-meta-data-one span {
    margin: 0 0.5em 0 0; }

.search-country a {
  color: #f0b03b;
  font-weight: 700; }

.search-filter-pane .item-list ul li {
  margin: 0 0 0.25em;
  list-style-type: none;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-size: 0.9375rem; }
  .search-filter-pane .item-list ul li a {
    display: inline-block; }
    .search-filter-pane .item-list ul li a:first-letter {
      text-transform: capitalize; }

/**
*
* Front
*
**/
.front-banner {
  min-height: 400px;
  background: #e0e0e0 url(../images/front-banner.jpg);
  background-size: cover;
  padding: 4em 0 2em 0;
  border-bottom: 1px solid #e0e0e0; }

@media (min-width: 61.25em) {
  .intro {
    width: 70%; } }

.intro p {
  font-size: 24px;
  font-size: 1.5rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #fff;
  text-shadow: 1px 0 1px rgba(0, 0, 0, 0.25); }
  @media (min-width: 61.25em) {
    .intro p {
      font-size: 30px;
      font-size: 1.875rem; } }

.register-button {
  display: inline-block;
  background: #f0b03b;
  padding: 10px 30px;
  margin: 0 10px 1em 0;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s; }
  .register-button:hover {
    background: #0d6839;
    color: #fff; }

@media (min-width: 61.25em) {
  .front .front-cta-section .contain {
    width: 68em; } }

@media (min-width: 81.25em) {
  .front .front-cta-section .contain {
    width: 94em; }
    .front .front-cta-section .contain .view-business-areas h2 {
      min-height: 1.3em; } }

.front-cta-section {
  padding: 3em 0;
  background: #f7f7f7; }

.view-business-areas {
  text-align: center; }
  .view-business-areas:before, .view-business-areas:after {
    content: "";
    display: table; }
  .view-business-areas:after {
    clear: both; }

.view-business-areas .views-row {
  margin: 0  0 1em;
  vertical-align: top;
  background: #fff;
  padding: 1em; }
  @media (min-width: 61.25em) {
    .view-business-areas .views-row {
      width: 22.5%;
      min-height: 18em;
      margin: 0 2% 0 0;
      display: inline-block; } }
  .view-business-areas .views-row:last-child {
    margin: 0; }

.view-business-areas h2 {
  display: table;
  margin: 0 auto 1em;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  min-height: 2.6em;
  text-transform: uppercase; }
  .view-business-areas h2 a {
    display: table-cell;
    vertical-align: middle; }

.biz-area-image img {
  display: inline-block;
  margin: 0 0 1em;
  border-bottom: 7px solid #f0b03b; }
  @media (min-width: 61.25em) {
    .biz-area-image img {
      width: 90%;
      height: auto; } }

.view-business-areas .biz-area-description {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.3;
  margin: 0 0 2em; }

.view-business-areas .biz-area-btn a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s; }
  .view-business-areas .biz-area-btn a:hover {
    background: #f0b03b;
    color: #fff; }

.front-map-section {
  padding: 2em 0 0 0;
  text-align: center; }

.front-map-section .pane-title {
  margin: 0 0 0.5em;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.3; }

.front-map-section .views-exposed-widget label {
  display: none; }

.front-map-section .views-exposed-widget .form-item .form-item .form-item {
  display: inline-block;
  margin: 0 1em 0 0;
  padding: 0 1em 0 0;
  border-right: 1px solid #e0e0e0; }
  .front-map-section .views-exposed-widget .form-item .form-item .form-item:last-child {
    margin: 0;
    padding: 0;
    border: none; }

.front-map-section form {
  margin: 0 0 2em; }

.front-map-section .views-submit-button {
  display: none; }

.front-featured-section {
  padding: 3em 0;
  background: #f7f7f7; }

.front-featured-section h2 {
  text-transform: uppercase; }

.featured-content-pane:before, .featured-content-pane:after {
  content: "";
  display: table; }

.featured-content-pane:after {
  clear: both; }

@media (min-width: 61.25em) {
  .featured-content-pane {
    width: 50%;
    min-height: 220px;
    float: left;
    padding: 0 2em 0 0;
    border-right: 1px solid #e0e0e0; } }

@media (min-width: 61.25em) {
  .featured-content-pane .featured-media {
    width: 50%;
    min-width: 200px;
    padding: 0 0 1em 1em;
    float: right; }
    .featured-content-pane .featured-media img {
      display: block;
      width: 100%;
      height: auto; } }

h3.featured-content-title {
  font-size: 18px;
  font-size: 1.125rem;
  margin: 1em 0 0.5em; }
  @media (min-width: 61.25em) {
    h3.featured-content-title {
      margin-top: 0; } }

.featured-content-body {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 0.5em; }

.featured-content-body a {
  font-weight: 700; }

.featured-content-meta-data {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  color: #848484; }

.featured-content-author {
  padding: 0 0.5em 0 0; }

.featured-group-pane {
  margin-top: 2em; }
  .featured-group-pane:before, .featured-group-pane:after {
    content: "";
    display: table; }
  .featured-group-pane:after {
    clear: both; }
  @media (min-width: 61.25em) {
    .featured-group-pane {
      min-height: 220px;
      width: 47%;
      float: right;
      margin-top: 0; } }

@media (min-width: 61.25em) {
  .featured-group-pane .featured-media {
    width: 50%;
    min-width: 200px;
    padding: 0 0 1em 1em;
    float: right; }
    .featured-group-pane .featured-media img {
      display: block;
      width: 100%;
      height: auto; } }

h3.featured-group-title {
  font-size: 18px;
  font-size: 1.125rem;
  margin: 1em 0 0.5em; }
  @media (min-width: 61.25em) {
    h3.featured-group-title {
      margin-top: 0; } }

.featured-group-body {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 0.5em; }

.featured-group-body a {
  font-weight: 700; }

.featured-group-meta-data {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  color: #848484; }

.featured-group-user {
  padding: 0 0.5em 0 0; }

.front-feeds-section {
  padding: 3em 0; }

@media (min-width: 61.25em) {
  .feed-section-one {
    width: 45%;
    float: left; } }

@media (min-width: 61.25em) {
  .feed-section-two {
    width: 45%;
    float: right; } }

.latest-content-pane h2 {
  text-transform: uppercase;
  padding: 0 0 1em;
  margin: 0 0 0.5em;
  border-bottom: 1px solid #e0e0e0; }

.view-latest-content {
  margin: 0 0 2em; }

.view-latest-content .views-row {
  padding: 1em 0;
  border-bottom: 1px solid #e0e0e0; }
  .view-latest-content .views-row:before, .view-latest-content .views-row:after {
    content: "";
    display: table; }
  .view-latest-content .views-row:after {
    clear: both; }
  .view-latest-content .views-row:first-child {
    padding-top: 0; }
  .view-latest-content .views-row:last-child {
    padding-bottom: 0;
    border: none; }

.main-content .latest-content-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0; }
  .main-content .latest-content-title .title-label {
    font-weight: normal;
    color: #747474; }

.latest-content-flag {
  font-size: 15px;
  font-size: 0.9375rem;
  padding: 0 0 0 0.5em;
  margin: 0 0 0 0.25em;
  border-left: 1px solid #e0e0e0;
  color: #f0b03b;
  white-space: nowrap; }

.latest-content-image {
  float: left;
  margin: 0.5em 1em 0.5em  0;
  width: 100px; }
  .latest-content-image a {
    display: block; }
  .latest-content-image img {
    width: 100px;
    height: auto;
    display: block; }

.latest-content-body {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 0.25em; }

.latest-content-meta-data-one {
  margin: 0 0 0.25em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 13px;
  font-size: 0.8125rem; }
  .latest-content-meta-data-one a {
    color: #a2a2a2; }
    .latest-content-meta-data-one a:hover {
      color: #0d6839; }

.latest-content-meta-data-one .latest-content-country {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid #e0e0e0; }
  .latest-content-meta-data-one .latest-content-country a {
    color: #f0b03b; }

.latest-content-meta-data-one span {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid #e0e0e0; }
  .latest-content-meta-data-one span:last-child {
    border: none;
    padding: 0;
    margin: 0; }

.latest-content-meta-data-two {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-size: 0.8125rem; }
  .latest-content-meta-data-two .latest-content-author {
    padding: 0 0.5em 0 0; }

.twitter-pane {
  margin: 0 0 1.75em;
  padding: 0 0 1em;
  border-bottom: 1px solid #e0e0e0; }

.twitter-pane h2 {
  text-transform: uppercase;
  padding: 0 0 1em;
  border-bottom: 1px solid #e0e0e0; }

.latest-activity-pane h2 {
  text-transform: uppercase;
  margin: 0 0 0.5em;
  padding: 0 0 1em;
  border-bottom: 1px solid #e0e0e0; }

.view-latest-activity .views-row {
  padding: 0.5em 0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 13px;
  font-size: 0.8125rem; }
  .view-latest-activity .views-row:first-child {
    padding-top: 0; }
  .view-latest-activity .views-row:last-child {
    border: none; }

/**
*
* User
*
**/
.toboggan-unified #login-message {
  margin: 0 0 1em;
  padding: 0 0 1em;
  border-bottom: 1px solid #e0e0e0; }

.toboggan-unified #login-links {
  margin: 0 0 1em;
  padding: 0 0 1em;
  border-bottom: 1px solid #e0e0e0; }
  .toboggan-unified #login-links a {
    display: inline-block;
    border-right: 1px solid #e0e0e0;
    padding: 0 1em 0 0;
    margin: 0 1em 0 0;
    font-weight: 700; }
    .toboggan-unified #login-links a.lt-active {
      color: #e69b12; }
    .toboggan-unified #login-links a:last-child {
      margin: 0;
      padding: 0;
      border: none; }

.toboggan-unified #register-form legend {
  display: block;
  margin: 0 0 0.5em;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700; }

.toboggan-unified .login-forgot {
  margin: 0 0 1em; }

/**
*
* Profile
*
**/
.user-image-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0; }
  .user-image-pane img {
    width: 100%;
    height: auto;
    display: inline-block;
    margin: 0; }

.user-orgs-pane .pane-title,
.user-additional-details-pane .pane-title {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0.75em;
  margin: 0;
  background: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-bottom: none; }

.user-orgs-pane .pane-content,
.user-additional-details-pane .pane-content {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0; }

.user-orgs-pane .user-org {
  padding: 0.25em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem;
  border-bottom: 1px solid #e0e0e0; }
  .user-orgs-pane .user-org:last-child {
    border: none;
    padding-bottom: 0; }

.user-additional-details-pane .user-joined {
  padding: 0 0 1em;
  border-bottom: 1px solid #e0e0e0;
  font-size: 13px;
  font-size: 0.8125rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.user-additional-details-pane .user-additional-contact-details {
  padding: 1em 0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 12px;
  font-size: 0.75rem; }

.user-additional-details-pane .user-skills-interests {
  padding: 1em 0;
  border-bottom: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .user-additional-details-pane .user-skills-interests .user-skills-interest-label {
    display: block;
    margin: 0 0 0.25em;
    font-weight: bold;
    color: #848484; }
  .user-additional-details-pane .user-skills-interests a {
    margin: 0 0.25em 0 0;
    padding: 0 0.5em 0 0;
    border-right: 1px solid #e0e0e0; }
    .user-additional-details-pane .user-skills-interests a:last-child {
      margin: 0;
      padding: 0;
      border: none; }

.user-additional-details-pane .user-profile-country {
  padding: 1em 0;
  border-bottom: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.user-additional-details-pane .account-websites {
  padding: 1em 0;
  border-bottom: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.user-additional-details-pane .user-social-acounts {
  padding: 1em 0;
  margin: 0 0 1em;
  border-bottom: 1px solid #e0e0e0; }

.user-social-acounts {
  margin: 0 0 1em; }

.user-social-acounts a {
  margin: 0 0.25em 0 0;
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  text-indent: -9999em; }

.user-social-acounts a:after {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "fontastic-custom-icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 0 -6px;
  text-indent: 0;
  font-size: 32px;
  font-size: 2rem;
  line-height: 0.75; }

.user-social-acounts .facebook-account:after {
  content: "C";
  color: #3b5998; }

.user-social-acounts .twitter-account:after {
  color: #4099FF;
  content: "A"; }

.user-social-acounts .linkedin-account:after {
  color: #0077b5;
  content: "B"; }

.user-social-acounts a:hover:after {
  opacity: 0.9; }

.msg-me-button {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 400;
  margin: 0 0 1em; }
  .msg-me-button:hover {
    background: #f0b03b;
    color: #fff; }

.user-follow-pane .flag {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 400;
  margin: 0 0 1em; }
  .user-follow-pane .flag:hover {
    background: #f0b03b;
    color: #fff; }

.user-about-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1em;
  border-bottom: 1px solid #e0e0e0; }

.user-groups-pane {
  margin: 0 0 1em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  @media (min-width: 61.25em) {
    .user-groups-pane {
      width: 47%;
      float: left;
      margin: 0 5% 0 0; } }
  .user-groups-pane .pane-title {
    margin: 0 0 0.5em; }

.user-content-pane {
  margin: 0 0 1em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  @media (min-width: 61.25em) {
    .user-content-pane {
      width: 47%;
      float: left;
      margin: 0; } }
  .user-content-pane .pane-title {
    margin: 0 0 0.5em; }

.user-opps-pane {
  margin: 0 0 1em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  @media (min-width: 61.25em) {
    .user-opps-pane {
      width: 50%; } }
  .user-opps-pane .pane-title {
    margin: 0 0 0.5em; }

.user-groups-pane .view .views-row,
.user-opps-pane .view .views-row,
.user-content-pane .view .views-row {
  padding: 0.25em 0;
  border-bottom: 1px solid #e0e0e0; }
  .user-groups-pane .view .views-row:first-child,
  .user-opps-pane .view .views-row:first-child,
  .user-content-pane .view .views-row:first-child {
    padding-top: 0; }
  .user-groups-pane .view .views-row:last-child,
  .user-opps-pane .view .views-row:last-child,
  .user-content-pane .view .views-row:last-child {
    border: none; }

/**
*
* Messages
*
**/
.messages-cta a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-size: 16px;
  font-size: 1rem; }
  .messages-cta a:hover {
    background: #f0b03b;
    color: #fff; }
  .messages-cta a:after {
    font-family: "fontastic-custom-icon-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-size: 0.875rem;
    content: "a";
    display: inline-block;
    margin: 0 0 0 0.5em;
    color: #ffcc00; }
  .messages-cta a:hover:after {
    color: #fff; }

.privatemsg-list .marker {
  color: #ff0000; }

/**
*
* Dashboard
*
**/
.page-dashboard .title {
  border-bottom: 1px solid #F0B03B;
  padding-bottom: 0.5em; }

.user-dashboard-section-one:before, .user-dashboard-section-one:after {
  content: "";
  display: table; }

.user-dashboard-section-one:after {
  clear: both; }

@media (min-width: 61.25em) {
  .user-dashboard-view-one {
    float: left;
    width: 48%; } }

@media (min-width: 61.25em) {
  .user-dashboard-view-two {
    float: right;
    width: 48%; } }

.view-dashboard-content .views-row {
  padding: 1em 0;
  border-bottom: 1px solid #e0e0e0; }
  .view-dashboard-content .views-row:last-child {
    border-bottom: none; }

.view-dashboard-opps .views-row {
  padding: 1em 0;
  border-bottom: 1px solid #e0e0e0; }
  .view-dashboard-opps .views-row:last-child {
    border-bottom: none; }

.dashboard-latest-content .ui-tabs-panel {
  border: 1px solid #e0e0e0; }

.dashboard-latest-content > .item-list {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }

.dashboard-latest-content .ui-tabs-nav {
  font-size: 0px;
  font-size: 0rem;
  margin: 0;
  border-bottom: 2px solid #0d6839;
  font-size: 0; }
  .dashboard-latest-content .ui-tabs-nav li {
    width: 50%; }
  .dashboard-latest-content .ui-tabs-nav a {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    width: 100%;
    margin: 0;
    background: white;
    color: #747474;
    text-align: center;
    border: 1px solid #e0e0e0;
    border-bottom: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
  .dashboard-latest-content .ui-tabs-nav .ui-state-active a {
    background: #0d6839;
    border: 1px solid #0d6839;
    border-bottom: none;
    color: white; }

.dashboard-network-public-content h4 {
  font-size: 16px;
  font-size: 1rem; }
  .dashboard-network-public-content h4 .title-label {
    font-weight: 400;
    color: #747474; }
  .dashboard-network-public-content h4 .flag {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }
  .dashboard-network-public-content h4 .fav-content-meta-data {
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700; }

.dashboard-network-public-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid #e0e0e0; }
  .dashboard-network-public-content .views-row:last-child {
    border-bottom: none; }

.dashboard-network-public-content .network-public-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle; }
  .dashboard-network-public-content .network-public-content-img img {
    width: 100%;
    height: 100%; }

.dashboard-network-public-content .network-public-content-container {
  margin: 0 0 0 5em; }

.dashboard-network-public-content .network-public-content-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0 0 0.25em; }
  .dashboard-network-public-content .network-public-content-title .title-label {
    font-weight: 400;
    color: #747474; }
  .dashboard-network-public-content .network-public-content-title .network-public-content-flag {
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.dashboard-network-public-content .network-public-content-meta-data {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #747474; }

.dashboard-network-public-content .network-public-content-author {
  margin: 0 0.5em 0 0; }

.dashboard-relevant-content h4 {
  font-size: 16px;
  font-size: 1rem; }
  .dashboard-relevant-content h4 .title-label {
    font-weight: 400;
    color: #747474; }
  .dashboard-relevant-content h4 .flag {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }
  .dashboard-relevant-content h4 .fav-content-meta-data {
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700; }

.dashboard-relevant-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid #e0e0e0; }
  .dashboard-relevant-content .views-row:last-child {
    border-bottom: none; }

.dashboard-relevant-content .user-relevant-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle; }
  .dashboard-relevant-content .user-relevant-content-img img {
    width: 100%;
    height: 100%; }

.dashboard-relevant-content .user-relevant-content-container {
  margin: 0 0 0 5em; }

.dashboard-relevant-content .user-relevant-content-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0 0 0.25em; }
  .dashboard-relevant-content .user-relevant-content-title .title-label {
    font-weight: 400;
    color: #747474; }
  .dashboard-relevant-content .user-relevant-content-title .user-relevant-content-flag {
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.dashboard-relevant-content .user-relevant-content-meta-data {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #747474; }

.dashboard-relevant-content .user-relevant-content-author {
  margin: 0 0.5em 0 0; }

.dashboard-user-content {
  margin: 2em 0; }
  @media (min-width: 61.25em) {
    .dashboard-user-content {
      margin: 0; } }

.dashboard-user-content > .item-list {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }

.dashboard-user-content .ui-tabs-nav {
  font-size: 0px;
  font-size: 0rem;
  margin: 0;
  border-bottom: 2px solid #0d6839;
  font-size: 0; }
  .dashboard-user-content .ui-tabs-nav li {
    width: 33.33%; }
    @media (min-width: 81.25em) {
      .dashboard-user-content .ui-tabs-nav li {
        width: auto; } }
  .dashboard-user-content .ui-tabs-nav a {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    width: 100%;
    margin: -2px 0 0;
    background: white;
    color: #747474;
    text-align: center;
    border: 1px solid #e0e0e0;
    border-bottom: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
  .dashboard-user-content .ui-tabs-nav .ui-state-active a {
    background: #0d6839;
    border: 1px solid #0d6839;
    border-bottom: none;
    color: white; }

.dashboard-favourite-content {
  margin: 0 0 2em;
  border: 1px solid #e0e0e0; }

.dashboard-favourite-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid #e0e0e0; }
  .dashboard-favourite-content .views-row:last-child {
    border-bottom: none; }

.dashboard-favourite-content .fav-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle; }
  .dashboard-favourite-content .fav-content-img img {
    width: 100%;
    height: 100%; }

.dashboard-favourite-content .fav-content-container {
  margin: 0 0 0 5em; }

.dashboard-favourite-content .fav-content-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0 0 0.25em; }
  .dashboard-favourite-content .fav-content-title .title-label {
    font-weight: 400;
    color: #747474; }
  .dashboard-favourite-content .fav-content-title .fav-content-flag {
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.dashboard-favourite-content .fav-content-meta-data {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #747474; }

.dashboard-favourite-content .fav-content-author {
  margin: 0 0.5em 0 0; }

.dashboard-favourite-groups {
  margin: 0 0 2em;
  border: 1px solid #e0e0e0; }

.dashboard-favourite-groups .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid #e0e0e0; }
  .dashboard-favourite-groups .views-row:last-child {
    border-bottom: none; }

.dashboard-favourite-groups .fav-group-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle; }
  .dashboard-favourite-groups .fav-group-img img {
    width: 100%;
    height: 100%; }

.dashboard-favourite-groups .fav-group-container {
  margin: 0 0 0 5em; }

.dashboard-favourite-groups .fav-group-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0 0 0.25em; }
  .dashboard-favourite-groups .fav-group-title .title-label {
    font-weight: 400;
    color: #747474; }
  .dashboard-favourite-groups .fav-group-title .fav-group-flag {
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.dashboard-favourite-groups .fav-group-meta-data {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #747474; }

.dashboard-favourite-groups .fav-group-date,
.dashboard-favourite-groups .fav-group-members {
  margin: 0 0.5em 0 0; }

.dashboard-draft-content {
  margin: 0 0 2em;
  border: 1px solid #e0e0e0; }

.dashboard-draft-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid #e0e0e0; }
  .dashboard-draft-content .views-row:last-child {
    border-bottom: none; }

.dashboard-draft-content .draft-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle; }
  .dashboard-draft-content .draft-content-img img {
    width: 100%;
    height: 100%; }

.dashboard-draft-content .draft-content-container {
  margin: 0 0 0 5em; }

.dashboard-draft-content .draft-content-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0 0 0.25em; }
  .dashboard-draft-content .draft-content-title .title-label {
    font-weight: 400;
    color: #747474; }
  .dashboard-draft-content .draft-content-title .draft-content-flag {
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.dashboard-draft-content .draft-content-meta-data {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #747474; }

.dashboard-draft-content .draft-content-author {
  margin: 0 0.5em 0 0; }

.dashboard-published-content {
  margin: 0 0 2em;
  border: 1px solid #e0e0e0; }

.dashboard-published-content .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid #e0e0e0; }
  .dashboard-published-content .views-row:last-child {
    border-bottom: none; }

.dashboard-published-content .published-content-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle; }
  .dashboard-published-content .published-content-img img {
    width: 100%;
    height: 100%; }

.dashboard-published-content .published-content-container {
  margin: 0 0 0 5em; }

.dashboard-published-content .published-content-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0 0 0.25em; }
  .dashboard-published-content .published-content-title .title-label {
    font-weight: 400;
    color: #747474; }
  .dashboard-published-content .published-content-title .published-content-flag {
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.dashboard-published-content .published-content-meta-data {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #747474; }

.dashboard-published-content .published-content-author {
  margin: 0 0.5em 0 0; }

.dashboard-joined-groups {
  margin: 0 0 2em;
  border: 1px solid #e0e0e0; }

.dashboard-joined-groups .views-row {
  padding: 1em 0.75em;
  position: relative;
  border-bottom: 1px solid #e0e0e0; }
  .dashboard-joined-groups .views-row:last-child {
    border-bottom: none; }

.dashboard-joined-groups .joined-group-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 1em;
  overflow: hidden;
  vertical-align: middle; }
  .dashboard-joined-groups .joined-group-img img {
    width: 100%;
    height: 100%; }

.dashboard-joined-groups .joined-group-container {
  margin: 0 0 0 5em; }

.dashboard-joined-groups .joined-group-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0 0 0.25em; }
  .dashboard-joined-groups .joined-group-title .title-label {
    font-weight: 400;
    color: #747474; }
  .dashboard-joined-groups .joined-group-title .joined-group-flag {
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.dashboard-joined-groups .joined-group-meta-data {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #747474; }

.dashboard-joined-groups .joined-group-date,
.dashboard-joined-groups .joined-group-members {
  margin: 0 0.5em 0 0; }

.dashboard-approval-content {
  margin: 2em 0; }

.dashboard-approval-content > .item-list {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }

.dashboard-approval-content .ui-tabs-nav {
  font-size: 0px;
  font-size: 0rem;
  margin: 0;
  border-bottom: 2px solid #0d6839;
  font-size: 0; }
  .dashboard-approval-content .ui-tabs-nav li {
    width: 33.33%; }
    @media (min-width: 61.25em) {
      .dashboard-approval-content .ui-tabs-nav li {
        width: auto; } }
  .dashboard-approval-content .ui-tabs-nav a {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    width: 100%;
    margin: -2px 0 0;
    background: white;
    color: #747474;
    text-align: center;
    border: 1px solid #e0e0e0;
    border-bottom: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
  .dashboard-approval-content .ui-tabs-nav .ui-state-active a {
    background: #0d6839;
    border: 1px solid #0d6839;
    border-bottom: none;
    color: white; }

.dashboard-admin-approvals {
  border: 1px solid #e0e0e0; }
  .dashboard-admin-approvals .views-row {
    padding: 1em;
    border-bottom: 1px solid #e0e0e0;
    position: relative; }
    .dashboard-admin-approvals .views-row:last-child {
      border-bottom: none; }

.dashboard-approval-group-container {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  margin: 0 11em 0 0; }

h4.dashboard-approval-group-request {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  margin: 0 11em 0.75em 0; }
  h4.dashboard-approval-group-request a {
    font-weight: 700; }

.dashboard-approval-group-operations {
  margin: 1em 0 0;
  text-align: right; }
  @media (min-width: 46.25em) {
    .dashboard-approval-group-operations {
      position: absolute;
      right: 0.5em;
      bottom: 0.5em;
      margin: 0; } }
  .dashboard-approval-group-operations .links {
    list-style-type: none; }
  .dashboard-approval-group-operations a {
    display: inline-block;
    border: none;
    background: #0d6839;
    padding: 10px 18px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    font-size: 0.75rem;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.3s;
    margin: 0 0 0.5em; }
    .dashboard-approval-group-operations a:hover {
      background: #f0b03b;
      color: #fff; }

.dashboard-approval-group-roles .item-list {
  margin: 1em 0; }

.dashboard-admin-org-approvals .group-last-update {
  margin: 0 11em 0.5em 0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem; }

.dashboard-admin-org-approvals .pub-group-status {
  margin: 0 11em 0 0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem; }

.pane-afaas-nutch-nutch-admin .view-nutch-admin .view-header {
  padding: 1em;
  border-bottom: 1px solid #e0e0e0; }
  .pane-afaas-nutch-nutch-admin .view-nutch-admin .view-header p {
    margin: 0; }
  .pane-afaas-nutch-nutch-admin .view-nutch-admin .view-header .crawler-button {
    display: inline-block;
    border: none;
    background: #0d6839;
    padding: 10px 18px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    font-size: 0.75rem;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.3s; }
    .pane-afaas-nutch-nutch-admin .view-nutch-admin .view-header .crawler-button:hover {
      background: #f0b03b;
      color: #fff; }

.pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-crawler-link {
  margin: 0 11em 0.5em 0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-description {
  margin: 0 11em 0.5em 0;
  font-size: 14px;
  font-size: 0.875rem; }

.pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-edit-link {
  margin: 0 0 0.5em; }
  @media (min-width: 46.25em) {
    .pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-edit-link {
      position: absolute;
      right: 6.5em;
      bottom: 1em; } }
  .pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-edit-link a {
    display: inline-block;
    border: none;
    background: #0d6839;
    padding: 10px 18px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    font-size: 0.75rem;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.3s; }
    .pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-edit-link a:hover {
      background: #f0b03b;
      color: #fff; }

.pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-delete-link {
  margin: 0 0 0.5em; }
  @media (min-width: 46.25em) {
    .pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-delete-link {
      position: absolute;
      right: 1em;
      bottom: 1em; } }
  .pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-delete-link a {
    display: inline-block;
    border: none;
    background: #0d6839;
    padding: 10px 18px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    font-size: 0.75rem;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.3s; }
    .pane-afaas-nutch-nutch-admin .view-nutch-admin .nutch-delete-link a:hover {
      background: #f0b03b;
      color: #fff; }

.dashboard-admin-public-approvals,
.dashboard-admin-afaas-approvals,
.dashboard-admin-country-approvals,
.dashboard-admin-org-approvals,
.dashboard-admin-nutch {
  border: 1px solid #e0e0e0; }
  .dashboard-admin-public-approvals .views-row,
  .dashboard-admin-afaas-approvals .views-row,
  .dashboard-admin-country-approvals .views-row,
  .dashboard-admin-org-approvals .views-row,
  .dashboard-admin-nutch .views-row {
    padding: 1em;
    border-bottom: 1px solid #e0e0e0;
    position: relative; }
    .dashboard-admin-public-approvals .views-row:before, .dashboard-admin-public-approvals .views-row:after,
    .dashboard-admin-afaas-approvals .views-row:before,
    .dashboard-admin-afaas-approvals .views-row:after,
    .dashboard-admin-country-approvals .views-row:before,
    .dashboard-admin-country-approvals .views-row:after,
    .dashboard-admin-org-approvals .views-row:before,
    .dashboard-admin-org-approvals .views-row:after,
    .dashboard-admin-nutch .views-row:before,
    .dashboard-admin-nutch .views-row:after {
      content: "";
      display: table; }
    .dashboard-admin-public-approvals .views-row:after,
    .dashboard-admin-afaas-approvals .views-row:after,
    .dashboard-admin-country-approvals .views-row:after,
    .dashboard-admin-org-approvals .views-row:after,
    .dashboard-admin-nutch .views-row:after {
      clear: both; }
    .dashboard-admin-public-approvals .views-row:last-child,
    .dashboard-admin-afaas-approvals .views-row:last-child,
    .dashboard-admin-country-approvals .views-row:last-child,
    .dashboard-admin-org-approvals .views-row:last-child,
    .dashboard-admin-nutch .views-row:last-child {
      border-bottom: none; }

.dashboard-approval-container {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  margin: 0 11em 0 0; }

.dashboard-approval-title {
  font-size: 16px;
  font-size: 1rem; }

.dashboard-approval-author,
.dashboard-approval-date,
.dashboard-approval-status {
  display: inline-block;
  margin: 0 1em 0 0; }

.dashboard-approval-approved {
  margin: 1em 0 0; }
  @media (min-width: 46.25em) {
    .dashboard-approval-approved {
      position: absolute;
      right: 1em;
      bottom: 1em;
      margin: 0; } }
  .dashboard-approval-approved a {
    display: inline-block;
    border: none;
    background: #0d6839;
    padding: 10px 18px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    font-size: 0.75rem;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.3s; }
    .dashboard-approval-approved a:hover {
      background: #f0b03b;
      color: #fff; }

/**
*
* People
*
**/
.people-grpoups-orgs .views-row {
  padding: 1.5em 0;
  border-bottom: 1px solid #e0e0e0; }
  .people-grpoups-orgs .views-row:before, .people-grpoups-orgs .views-row:after {
    content: "";
    display: table; }
  .people-grpoups-orgs .views-row:after {
    clear: both; }
  .people-grpoups-orgs .views-row:last-child {
    border-bottom: none; }

.user-list-picture {
  display: inline-block;
  width: 50px;
  margin: 0 1em 0 0;
  vertical-align: top; }
  @media (min-width: 46.25em) {
    .user-list-picture {
      width: 100px; } }
  .user-list-picture img {
    display: block;
    width: 100%;
    height: auto; }

.view-people .user-data-container {
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
  width: 75%; }

h3.user-name {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  margin: 0 0 0.5em; }

.user-meta-data-role {
  font-weight: 400;
  padding: 0 0 0 0.5em;
  margin: 0 0 0 0.25em;
  border-left: 1px solid #e0e0e0; }

.user-meta-data-one,
.user-meta-data-two {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.user-country,
.user-specialization,
.user-keywords {
  font-weight: 700;
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #e0e0e0; }
  .user-country a,
  .user-specialization a,
  .user-keywords a {
    color: #a2a2a2; }
    .user-country a:hover,
    .user-specialization a:hover,
    .user-keywords a:hover {
      color: #0d6839; }
  .user-country:last-child,
  .user-specialization:last-child,
  .user-keywords:last-child {
    border: none;
    padding: 0;
    margin: 0; }

.user-country a {
  color: #f0b03b;
  text-transform: uppercase; }

.user-meta-data-two span {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #e0e0e0; }
  .user-meta-data-two span:last-child {
    border: none; }

/**
*
* Organization
*
**/
.org-image {
  display: inline-block;
  width: 50px;
  margin: 0 1em 0 0;
  vertical-align: top; }
  @media (min-width: 46.25em) {
    .org-image {
      width: 100px; } }
  .org-image img {
    display: block;
    width: 100%;
    height: auto; }

.view-group .org-data-container {
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
  width: 75%; }

h3.org-title {
  margin: 0 0 0.5em;
  font-size: 18px;
  font-size: 1.125rem; }
  h3.org-title .title-label {
    font-weight: 400;
    color: #747474; }

.org-about {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 0.25em; }

.org-meta-data-one,
.org-meta-data-two {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.org-country,
.org-subject,
.org-keywords {
  font-weight: 700;
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #a2a2a2; }
  .org-country a,
  .org-subject a,
  .org-keywords a {
    color: #a2a2a2; }
    .org-country a:hover,
    .org-subject a:hover,
    .org-keywords a:hover {
      color: #0d6839; }
  .org-country:last-child,
  .org-subject:last-child,
  .org-keywords:last-child {
    border: none;
    padding: 0;
    margin: 0; }

.org-country {
  text-transform: uppercase; }
  .org-country a {
    color: #f0b03b; }

.org-meta-data-two span:last-child {
  border: none;
  padding: 0;
  margin: 0; }

.org-joined {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #e0e0e0; }

.org-members {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #e0e0e0; }

.pane-organization-image-and-video-view {
  margin: 0 0 1.5em; }
  .pane-organization-image-and-video-view img {
    width: 100%;
    height: auto; }

.org-mission-pane {
  padding: 0 0 1.5em;
  margin: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

.org-about-pane {
  padding: 0 0 1.5em;
  margin: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

.org-member-date-pane {
  padding: 0 0 1.5em;
  margin: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

.org-member-date-pane .views-field-created-1 {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-size: 1.125rem; }
  .org-member-date-pane .views-field-created-1 .views-label {
    font-weight: 700; }

.org-site-pane {
  margin: 0 0 1em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700; }

.org-linkedin-pane,
.org-facebook-pane,
.org-twitter-pane {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem;
  display: inline-block;
  margin: 0 1em 0 0;
  padding: 0 1em 0 0;
  border-right: 1px solid #e0e0e0; }

.org-facebook-pane {
  border: none;
  padding: 0;
  margin: 0; }

.org-location-pane .field-organisation-location {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .org-location-pane .field-organisation-location .country {
    font-weight: 700;
    color: #a2a2a2; }

/**
*
* Groups
*
**/
.view-groups .views-row {
  padding: 1.5em 0;
  border-bottom: 1px solid #e0e0e0; }
  .view-groups .views-row:before, .view-groups .views-row:after {
    content: "";
    display: table; }
  .view-groups .views-row:after {
    clear: both; }
  .view-groups .views-row:last-child {
    border-bottom: none; }

.view-groups .views-row h3 {
  margin: 0 0 0.5em; }
  .view-groups .views-row h3 .title-label {
    font-weight: 400;
    color: #747474; }

.group-image {
  display: inline-block;
  width: 50px;
  margin: 0 1em 0 0;
  vertical-align: top; }
  @media (min-width: 46.25em) {
    .group-image {
      width: 100px; } }
  .group-image img {
    display: block;
    width: 100%;
    height: auto; }

.view-group .group-data-container {
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
  width: 75%; }

.group-about {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 0.25em; }

.group-meta-data-one,
.group-meta-data-two {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.group-country,
.group-subject,
.group-keywords {
  font-weight: 700;
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #a2a2a2; }
  .group-country a,
  .group-subject a,
  .group-keywords a {
    color: #a2a2a2; }
    .group-country a:hover,
    .group-subject a:hover,
    .group-keywords a:hover {
      color: #0d6839; }
  .group-country:last-child,
  .group-subject:last-child,
  .group-keywords:last-child {
    border: none;
    padding: 0;
    margin: 0; }

.group-meta-data-two span {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #e0e0e0; }
  .group-meta-data-two span:last-child {
    border: none; }

.group-country {
  text-transform: uppercase; }
  .group-country a {
    color: #f0b03b; }

.pane-group-image-and-video-view {
  margin: 0 0 1.5em; }
  .pane-group-image-and-video-view img {
    width: 100%;
    height: auto; }

.group-mission-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

.group-about-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

.group-creation-date-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-size: 1.125rem; }
  .group-creation-date-pane .views-label {
    font-weight: 700; }

.group-site-pane {
  margin: 0 0 1em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700; }

/**
*
* Resources
*
**/
.view-resources .views-row {
  padding: 1.5em 0;
  border-bottom: 1px solid #e0e0e0; }
  .view-resources .views-row:before, .view-resources .views-row:after {
    content: "";
    display: table; }
  .view-resources .views-row:after {
    clear: both; }
  .view-resources .views-row:last-child {
    border-bottom: none; }

.view-resources .views-row h3 {
  margin: 0 0 0.5em; }
  .view-resources .views-row h3 .title-label {
    font-weight: 400;
    color: #747474; }
  .view-resources .views-row h3 .res-flag {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.view-resources .resource-image {
  float: left;
  width: 100px;
  margin: 0 1em 2em 0; }
  .view-resources .resource-image img {
    display: block;
    width: 100px;
    height: auto; }

.view-resources .field-image-container {
  display: inline-block;
  width: 50px;
  margin: 0.5em 1em 0.5em 0;
  vertical-align: top; }
  @media (min-width: 46.25em) {
    .view-resources .field-image-container {
      width: 100px; } }
  .view-resources .field-image-container img {
    display: block;
    width: 100%;
    height: auto; }

.view-resources .res-content-container {
  display: inline-block;
  width: 75%; }

.res-description {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 0.25em; }

.res-content-meta-data-one {
  margin: 0 0 0.25em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700; }

.res-content-meta-data-one .res-content-country {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid #e0e0e0; }
  .res-content-meta-data-one .res-content-country a {
    color: #f0b03b; }

.res-content-meta-data-one a {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid #e0e0e0;
  color: #a2a2a2; }
  .res-content-meta-data-one a:hover {
    color: #0d6839; }
  .res-content-meta-data-one a:last-child {
    border: none;
    padding: 0;
    margin: 0; }

.res-content-meta-data-two {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .res-content-meta-data-two .res-content-author {
    padding: 0 0.5em 0 0; }

.view-resources .resource-body {
  margin: 0 0 0.5em; }

.view-resources .resource-body a {
  font-weight: 700; }

.node-type-resource .title {
  margin: 0 0 0.25em; }
  .node-type-resource .title span {
    margin: 0 0.1em 0 0;
    font-weight: 400;
    color: #747474; }

.node-type-resource .pane-node-field-real-author,
.node-type-resource .pane-node-created {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  display: inline-block;
  margin: 0 1em 1em 0; }
  .node-type-resource .pane-node-field-real-author div,
  .node-type-resource .pane-node-created div {
    display: inline; }

.res-country-forum-content-pane,
.res-afaas-content-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0; }
  .res-country-forum-content-pane .pane-title,
  .res-afaas-content-pane .pane-title {
    display: inline;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    color: #a2a2a2;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .res-country-forum-content-pane .pane-content,
  .res-afaas-content-pane .pane-content {
    display: none; }

/**
*
* Problem Solving
*
**/
.problem-solving-intro {
  padding: 0 0 1em;
  margin: 0 0 2em;
  border-bottom: 1px solid #e0e0e0; }

.view-problems .views-row {
  padding: 1.5em 0;
  border-bottom: 1px solid #e0e0e0; }
  .view-problems .views-row:before, .view-problems .views-row:after {
    content: "";
    display: table; }
  .view-problems .views-row:after {
    clear: both; }
  .view-problems .views-row:last-child {
    border-bottom: none; }

.view-problems .views-row h3 {
  margin: 0 0 0.2em;
  line-height: 1.5em; }
  .view-problems .views-row h3 .title-label {
    font-weight: 400;
    color: #747474; }

.view-problems .problem-image {
  display: inline-block;
  width: 50px;
  margin: 0.5em 1em 0.5em 0;
  vertical-align: top; }
  .view-problems .problem-image img {
    display: block;
    width: 100%;
    height: auto; }

.view-problems .problem-content-container {
  display: inline-block;
  width: 75%; }

.problem-content-meta-data-one {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  margin: 0 0 0.25em; }

.problem-content-created {
  margin: 0 0 0 0.5em; }

.problem-content-meta-data-two {
  margin: 0 0 0.25em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700; }
  .problem-content-meta-data-two .problem-country a {
    color: #f0b03b;
    padding: 0 0.5em 0 0;
    margin: 0 0.25em 0 0;
    border-right: 1px solid #e0e0e0; }
  .problem-content-meta-data-two > a:last-child {
    border: none;
    padding: 0;
    margin: 0; }

.problem-content-meta-data-two a {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid #e0e0e0;
  color: #a2a2a2; }
  .problem-content-meta-data-two a:hover {
    color: #0d6839; }

.problem-content-meta-data-three {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .problem-content-meta-data-three span {
    padding: 0 0.25em 0 0;
    margin: 0 0.25em 0 0; }
  .problem-content-meta-data-three .problem-answers {
    padding: 0 0 0 0.25em;
    margin: 0 0 0 0.25em; }
  .problem-content-meta-data-three .problem-follow-count {
    border-left: 1px solid #e0e0e0;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em; }

.view-problem-solve .problem-body {
  margin: 0 0 0.5em; }

.pane-node-field-problem {
  border-top: 1px solid #e0e0e0;
  padding: 1em 0 0 0;
  margin: 0 0 0.5em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1;
  font-weight: 700; }

.pane-node-field-problem .field-problem:before {
  content: 'Question:';
  color: #f0b03b; }

.node-type-problem .pane-node-author,
.node-type-problem .pane-node-submitted,
.node-type-problem .pane-node-created {
  display: inline-block;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .node-type-problem .pane-node-author .pane-content,
  .node-type-problem .pane-node-submitted .pane-content,
  .node-type-problem .pane-node-created .pane-content {
    display: inline-block; }

.problem-content-sms-id,
.problem-content-answer-count {
  display: inline-block;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  float: right;
  margin: 0 0 0 1em; }

.problem-comments-pane {
  margin: 2em 0 0; }

.problem-comments-pane .comment {
  padding: 1em;
  border: 1px solid #e0e0e0; }

.problem-comments-pane .title {
  color: #f0b03b; }

h4.problem-follow-title,
h4.problem-actions-title {
  margin: 0 0 0.5em; }

.problem-sms-button-pane,
.problem-email-button-pane,
.problem-reject-button-pane,
.problem-attention-button-pane,
.problem-close-button-pane {
  display: inline-block;
  padding: 0.5em 0; }

.problem-email-button-pane,
.problem-close-button-pane {
  margin: 0 0 1em; }

.problem-assignee-pane,
.problem-group-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem; }
  .problem-assignee-pane .pane-title,
  .problem-group-pane .pane-title {
    font-weight: 700;
    color: #a2a2a2;
    vertical-align: top; }
  .problem-assignee-pane .pane-content,
  .problem-group-pane .pane-content {
    display: inline-block; }

.problem-group-pane {
  margin: 0 0 1.5em; }

.problem-country-pane,
.problem-subject-area-pane,
.problem-keywords-pane {
  border-bottom: none;
  padding: 0.25em 0; }
  .problem-country-pane .field-label,
  .problem-subject-area-pane .field-label,
  .problem-keywords-pane .field-label {
    font-size: 16px;
    font-size: 1rem;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #a2a2a2;
    margin: 0 0.5em 0 0; }

.problem-comments-pane .comment .field-comment-body-container {
  padding: 0;
  margin: 0 0 1.5em;
  font-size: 13px;
  font-size: 0.8125rem; }

/**
*
* Opportunities
*
**/
.not-front .view-opps .views-row {
  padding: 1.5em 0;
  border-bottom: 1px solid #e0e0e0; }
  .not-front .view-opps .views-row:before, .not-front .view-opps .views-row:after {
    content: "";
    display: table; }
  .not-front .view-opps .views-row:after {
    clear: both; }
  .not-front .view-opps .views-row:last-child {
    border-bottom: none; }

h3.opp-content-title {
  margin: 0 0 0.5em; }
  h3.opp-content-title .title-label {
    font-weight: 400;
    color: #747474; }

.opp-image {
  display: inline-block;
  width: 50px;
  margin: 0 1em 0 0;
  vertical-align: top; }
  @media (min-width: 46.25em) {
    .opp-image {
      width: 100px; } }
  .opp-image img {
    display: block;
    width: 100%;
    height: auto; }

.view-group .opp-content-container {
  display: inline-block;
  width: 75%; }

.opp-description {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 0.25em; }

.opp-content-meta-data-one {
  margin: 0 0 0.25em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .opp-content-meta-data-one strong {
    color: #848484; }

.opp-content-meta-data-two {
  margin: 0 0 0.25em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700; }

.opp-content-meta-data-two a {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #e0e0e0;
  color: #a2a2a2; }
  .opp-content-meta-data-two a:hover {
    color: #0d6839; }
  .opp-content-meta-data-two a:last-child {
    border: none;
    padding: 0;
    margin: 0; }

.opp-content-meta-data-three {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .opp-content-meta-data-three .opp-started {
    padding: 0 0.5em 0 0; }
  .opp-content-meta-data-three .follow-count {
    padding: 0 0 0 0.5em;
    border-left: 1px solid #e0e0e0; }

.opp-countries {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #e0e0e0; }
  .opp-countries a {
    color: #f0b03b; }

.opp-list-map-pane .pane-title {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0.75em;
  margin: 0;
  background: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-bottom: none; }

.opp-list-map-pane .pane-content {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0; }

.pane-opportunity-image-and-video-view {
  margin: 0 0 1.5em; }
  .pane-opportunity-image-and-video-view img {
    width: 100%; }

.pane-group-field-short-description {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

.pane-group-field-description {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }

.opp-links-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid #e0e0e0; }
  .opp-links-pane .field-facebook-account-container,
  .opp-links-pane .field-twitter-link-container,
  .opp-links-pane .field-linkedin-container {
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-size: 1rem;
    display: inline-block;
    margin: 0 1em 0 0;
    padding: 0 1em 0 0;
    border-right: 1px solid #e0e0e0; }
  .opp-links-pane .field-linkedin-container {
    border: none;
    padding: 0;
    margin: 0; }

.opp-links-pane .field-link-container {
  margin: 0 0 1em 0;
  font-weight: 700; }

.opp-share-this-pane {
  margin: 0 0 0.5em; }

.opp-date-created-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem; }
  .opp-date-created-pane .date-created-label {
    font-weight: 700;
    color: #a2a2a2; }

.opp-seeking-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem; }
  .opp-seeking-pane .field-label {
    font-weight: 700;
    color: #a2a2a2; }

.opp-status-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem; }
  .opp-status-pane .pane-title {
    font-weight: 700;
    color: #a2a2a2; }
  .opp-status-pane .pane-content {
    display: inline-block; }

/**
*
* Stories
*
**/
.view-stories .views-row {
  padding: 1.5em 0;
  border-bottom: 1px solid #e0e0e0; }
  .view-stories .views-row:before, .view-stories .views-row:after {
    content: "";
    display: table; }
  .view-stories .views-row:after {
    clear: both; }
  .view-stories .views-row:last-child {
    border-bottom: none; }

.view-stories .views-row h3 {
  margin: 0 0 0.5em; }
  .view-stories .views-row h3 .title-label {
    font-weight: 400;
    color: #747474; }
  .view-stories .views-row h3 .story-flag {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.view-stories .field-image-container {
  display: inline-block;
  width: 50px;
  margin: 0.5em 1em 0.5em 0;
  vertical-align: top; }
  @media (min-width: 46.25em) {
    .view-stories .field-image-container {
      width: 100px; } }
  .view-stories .field-image-container img {
    display: block;
    width: 100%;
    height: auto; }

.view-stories .story-content-container {
  display: inline-block;
  width: 75%; }

.story-description {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 0.25em; }

.story-content-meta-data-one {
  margin: 0 0 0.25em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700; }

.story-content-meta-data-one .story-content-country {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid #e0e0e0; }
  .story-content-meta-data-one .story-content-country a {
    color: #f0b03b; }

.story-content-meta-data-one a {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #e0e0e0;
  color: #a2a2a2; }
  .story-content-meta-data-one a:hover {
    color: #0d6839; }
  .story-content-meta-data-one a:last-child {
    border: none;
    padding: 0;
    margin: 0; }

.story-content-meta-data-two {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .story-content-meta-data-two .story-content-author {
    padding: 0 0.5em 0 0; }

.node-type-blog .title {
  margin: 0 0 0.25em; }
  .node-type-blog .title span {
    margin: 0 0.1em 0 0;
    font-weight: 400;
    color: #747474; }

.node-type-blog .pane-node-field-real-author,
.node-type-blog .pane-node-created {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  display: inline-block;
  margin: 0 1em 1em 0; }
  .node-type-blog .pane-node-field-real-author div,
  .node-type-blog .pane-node-created div {
    display: inline; }

.story-afaas-content-pane,
.story-country-forum-content-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0; }
  .story-afaas-content-pane .pane-title,
  .story-country-forum-content-pane .pane-title {
    font-weight: 700;
    text-transform: uppercase;
    color: #f0b03b; }
  .story-afaas-content-pane .pane-content,
  .story-country-forum-content-pane .pane-content {
    display: none; }

/**
*
* News
*
**/
.view-news .views-row {
  padding: 1.5em 0;
  border-bottom: 1px solid #e0e0e0; }
  .view-news .views-row:before, .view-news .views-row:after {
    content: "";
    display: table; }
  .view-news .views-row:after {
    clear: both; }
  .view-news .views-row:last-child {
    border-bottom: none; }

.view-news .views-row h3 {
  margin: 0 0 0.5em; }
  .view-news .views-row h3 .title-label {
    font-weight: 400;
    color: #747474; }
  .view-news .views-row h3 .news-flag {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid #e0e0e0;
    color: #f0b03b;
    white-space: nowrap; }

.view-news .field-image-container {
  display: inline-block;
  width: 50px;
  margin: 0.5em 1em 0.5em 0;
  vertical-align: top; }
  @media (min-width: 46.25em) {
    .view-news .field-image-container {
      width: 100px; } }
  .view-news .field-image-container img {
    display: block;
    width: 100%;
    height: auto; }

.view-news .news-content-container {
  display: inline-block;
  width: 75%; }

.news-description {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 0.25em; }

.news-content-meta-data-one {
  margin: 0 0 0.25em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700; }

.news-content-meta-data-one .news-content-country {
  padding: 0 0.5em 0 0;
  margin: 0 0.5em 0 0;
  border-right: 1px solid #e0e0e0; }
  .news-content-meta-data-one .news-content-country a {
    color: #f0b03b; }

.news-content-meta-data-one a {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid #e0e0e0;
  color: #a2a2a2; }
  .news-content-meta-data-one a:hover {
    color: #0d6839; }
  .news-content-meta-data-one a:last-child {
    border: none;
    padding: 0;
    margin: 0; }

.news-content-meta-data-two {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }
  .news-content-meta-data-two .news-content-author {
    padding: 0 0.5em 0 0; }

.node-type-article .title {
  margin: 0 0 0.25em; }
  .node-type-article .title span {
    margin: 0 0.1em 0 0;
    font-weight: 400;
    color: #747474; }

.node-type-article .news-author-pane,
.node-type-article .news-created-date-pane {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  display: inline-block;
  margin: 0 1em 1em 0; }
  .node-type-article .news-author-pane div,
  .node-type-article .news-created-date-pane div {
    display: inline; }

.news-afaas-content-pane,
.news-country-forum-content-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid #e0e0e0; }
  .news-afaas-content-pane .pane-title,
  .news-country-forum-content-pane .pane-title {
    font-weight: 700;
    text-transform: uppercase;
    color: #f0b03b; }
  .news-afaas-content-pane .pane-content,
  .news-country-forum-content-pane .pane-content {
    display: none; }

/**
*
* Help Centre
*
**/
.faq-index-pane .pane-content > a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  margin: 0 0 2em; }
  .faq-index-pane .pane-content > a:hover {
    background: #f0b03b;
    color: #fff; }

@media (min-width: 61.25em) {
  .page-helpcentre .sidebar-left {
    width: 250px; } }

.page-helpcentre .main-content-right {
  margin: 2em 0 0; }
  @media (min-width: 61.25em) {
    .page-helpcentre .main-content-right {
      width: 500px;
      margin: 0;
      padding: 0 0 0 2em; } }

.faq-index-pane ul {
  list-style-type: none; }
  .faq-index-pane ul ul > ul, .faq-index-pane ul ul > li {
    padding: 0 0 0 0.5em; }
  .faq-index-pane ul li {
    position: relative; }

.faq-index-pane li > a {
  position: relative; }
  .faq-index-pane li > a:after {
    font-family: "fontastic-custom-icon-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "q";
    display: block;
    position: absolute;
    top: 0.9em;
    right: 0.5em;
    font-size: 1.2em;
    letter-spacing: -0.25em;
    margin: 0 0 0 0.5em;
    color: #cacaca; }

.faq-index-pane li.expanded a {
  border-bottom: 1px solid #e0e0e0; }

.faq-index-pane li.expanded > a:after {
  content: "p"; }

.faq-index-pane li.leaf > a:after,
.faq-index-pane a.ajax-processed:after {
  display: none; }

.faq-index-pane ul a {
  display: block;
  padding: 1em 0;
  font-size: 12px;
  font-size: 0.75rem; }

.faq-index-pane .ajax-progress-throbber {
  display: initial; }

.faq-index-pane .throbber {
  float: none;
  position: absolute;
  right: 0.25em;
  top: 0.75em;
  background: transparent url(../images/ajax-loader.gif) no-repeat center center;
  width: 16px;
  height: 16px; }

.faq-content-pane {
  font-size: 13px;
  font-size: 0.8125rem; }

.field-faq-block {
  margin: 2.5em 0; }

.field-faq-block h5 {
  color: #747474;
  color: #f0b03b; }

.field-faq-block ol,
.field-faq-block ul {
  margin: 0.5em 0 1em 1em;
  padding: 0.25em 0 1em 1em;
  list-style-position: inherit; }

.field-faq-block ul {
  list-style-type: circle; }

.field-faq-block li {
  margin: 0.5em 0; }

.faq-contact-form {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 2em 0 0; }

.faq-contact-form h2 {
  font-size: 20px;
  font-size: 1.25rem; }

.contact-support-text {
  margin: 0 0 1.5em; }

/**
*
* Doc
*
**/
.group-forum-doc-pane {
  margin: 0 0 1em; }

.group-forum-doc-description-pane {
  padding: 0 0 1em; }

/**
*
* Forum
*
**/
.forum-topic-legend {
  padding: 1em 0;
  margin: 2em 0 0 0;
  border-top: 1px solid #e0e0e0; }
  .forum-topic-legend div {
    display: inline-block;
    margin: 0 0.25em 0 0;
    padding: 0 0.5em 0 0;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-size: 0.875rem;
    border-right: 1px solid #e0e0e0; }
    .forum-topic-legend div:last-child {
      padding: 0;
      margin: 0;
      border: none; }

.node-type-forum .action-links a {
  background: #0d6839;
  color: #fff; }
  .node-type-forum .action-links a:hover {
    background: #f0b03b; }

.node-type-forum .comment-wrapper {
  padding: 0;
  border: none; }

.forum-post {
  padding: 1em 0; }

.forum-topic-header {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.forum-post-info {
  margin: 0 0 2em;
  padding: 1em;
  border: 1px solid #e0e0e0;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif; }

.topic-post-count {
  float: right; }

.topic-reply-link {
  float: left; }
  .topic-reply-link a {
    margin: 0 1em 0 0; }

.forum-posted-on {
  float: left; }

.forum-post-number {
  float: right; }

.forum-post .forum-post-title,
.forum-post .post-title {
  margin: 0 0 0.5em;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-size: 1.5rem; }

.forum-post-panel-sub {
  float: left;
  width: 140px;
  margin: 0 0 1em; }

.author-pane {
  padding: 0.5em;
  margin: 0 1em 0 0;
  border: 1px solid #e0e0e0; }
  .author-pane a {
    display: block;
    margin: 0 0 1em;
    font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-size: 0.875rem; }

.user-picture img {
  display: block;
  width: 100%; }

.forum-post-content .field-comment-body p {
  font-size: 13px;
  font-size: 0.8125rem; }

.post-edited {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem; }

.forum-post-links {
  width: 100%; }

.forum-post-links .post-edit a,
.forum-post-links .post-delete a {
  display: inline-block;
  border: none;
  background: #0d6839;
  padding: 10px 18px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: normal; }
  .forum-post-links .post-edit a:hover,
  .forum-post-links .post-delete a:hover {
    background: #f0b03b;
    color: #fff; }

.forum-post-links .links li {
  display: inline-block; }

.forum-post-links li.statistics_counter {
  display: block;
  float: right;
  margin: 0;
  text-align: right;
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem; }

.forum-post-links li.comment-add {
  margin: 0; }

.forum-jump-links {
  display: none; }
