/**
*
* Drupal Overrides
*
**/

// Drupal messages

.front .message-dialog  {
  display: none;
}

.message-dialog {
  margin: 0 0 2em;
  word-wrap: break-word;
  @include font-size(12px);
  &.messages--status {
    border-color: $blue;
  }
  .messages {
    margin: 0 0 1.5em;
  }
}

// Action links

.action-links {
  margin: 0 0 1em;
}

.action-links li {
  display: inline-block;
  list-style-type: none;
}

//  Panels

.contextual-links-region {
  @include clearfix;
}

// Filters

.filter-wrapper {
  margin: 0 0 1em;
}

// Tips

.tips {
  @include font-size(12px);
}

// Submitted

.submitted {
  margin: 0 0 1em;
  font-family: $fontTitle;
}

// Fildset

html.js fieldset.collapsed {
  height: 2em;
  @include clearfix;
}