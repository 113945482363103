/**
*
* Buttons
*
**/

// Flag - Like

.flag-like a {
  @include button;
  font-weight: $normal;
  &:after {
    @include icon;
    content: "h";
    font-size: 1.5em;
    line-height: 1em;
    vertical-align: bottom;
    letter-spacing: -0.25em;
    display: inline-block;
    margin: 0  0 0 0.5em;
    color: $bright-yellow;
  }
  &:hover:after {
    color: $white;
  }
}

.flag-follow-sms a {
  @include button;
  font-weight: $normal;
  &:after {
    @include icon;
    content: "a";
    line-height: 1.25em;
    vertical-align: middle;
    display: inline-block;
    margin: 0  0 0 0.5em;
    color: $bright-yellow;
  }
  &:hover:after {
    color: $white;
  }
}

.flag-follow-email a {
  @include button;
  font-weight: $normal;
  &:after {
    @include icon;
    content: "H";
    line-height: 1.25em;
    vertical-align: middle;
    display: inline-block;
    margin: 0  0 0 0.5em;
    color: $bright-yellow;
  }
  &:hover:after {
    color: $white;
  }
}

.flag-reject a,
.flag-attention a,
.flag-close a,
.flag-send-to-assignees-by-sms a {
  @include button;
  font-weight: $normal;
}

.flag-follow-group a {
  @include button;
  font-weight: $normal;
  &:hover:after {
    color: $white;
  }
}

// Comments

.comment-delete a,
.comment-edit a,
.comment-reply a,
.comment-add > a,
.flag-reject_comment a,
.flag-send_to_questioner_by_sms a {
  @include button;
  font-weight: $normal;
}

// Forum Doc

.node-type-document .action-links a {
  background: $green;
  color: $white;
  &:hover {
    background: $grey-200;
    color: $green;
  }
}
