/**
*
* Variables
*
**/

// Colors
$black: #000;
$white: #fff;
$blue: #0f405d;
$yellow: #f0b03b;
$bright-yellow: #ffcc00;
$light-green: #82957a;
$green: #0d6839;
$dark-green: #003622;
$red: #ff0000;

// Greys
$grey-100: #f7f7f7;
$grey-200: #e0e0e0;
$grey-300: #cacaca;
$grey-400: #a2a2a2;
$grey-500: #848484;
$grey-600: #747474;
$grey-700: #555;
$grey-800: #444;
$grey-900: #333;

// Fonts

$fontTitle: "Open Sans Condensed", Helvetica, Arial, sans-serif;
$fontBody: "Open Sans", Helvetica, Arial, sans-serif;

// Font Weights
$light: 300;
$normal: 400;
$semi-bold: 600;
$bold: 700;