/**
*
*  Search Results
*
**/

// Search Form

.pane-apachesolr-form {
  margin: 0 0 1em;
  .form-type-textfield {
    display: inline-block;
    width: 70%;
  }
  .form-actions {
    display: inline-block;
    width: 20%;
  }
}

// Search Results

.pane-apachesolr-result {
  margin: 0 0 2em;
}

.search-results {
  list-style: none;
}

.search-result {
  padding: 1.5em 0;
  border-bottom: 1px solid $grey-200;
  &:first-child {
    border-top: 1px solid $grey-200;
  }
  &:last-child {
    border-bottom: none;
  }
}

.search-partner-result {
  @include font-size(12px);
  font-family: $fontTitle;
  font-weight: $bold;
  margin: -0.5em 0 0.5em;
  text-transform: uppercase;
  color: $grey-400;
}

.search-result .title {
  margin: 0 0 0.5em;
  .title-label,
  .title-external-label {
    font-weight: $normal;
  }
  .certification {
    @include font-size(15px);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.25em;
    border-left: 1px solid $grey-200;
    color: $yellow;
    white-space: nowrap;
  }
}

.search-result-url {
  font-family: $fontTitle;
  margin: 0 0 0.25em;
}

.search-snippet-url {
  display: block;
  margin: 0 0 0.5em;
}

.search-result-content-container {
  @include font-size(13px);
}

.search-snippet a.read-more {
  font-weight: $bold;
  margin: 0 0 0 0.5em;
}

.search-snippet:last-child {
  margin: 0;
}

.search-result-meta-data-one {
  font-family: $fontTitle;
  span {
    margin: 0 0.5em 0 0;
  }
}

.search-country a {
  color: $yellow;
  font-weight: $bold;
}

// Search Sidebar blocks

.search-filter-pane .item-list ul li {
  margin: 0 0 0.25em;
  list-style-type: none;
  font-family: $fontTitle;
  @include font-size(15px);
  a {
    display: inline-block;
    &:first-letter {
      text-transform: capitalize;
    }
  }
}