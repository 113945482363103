/**
*
* Mixins
*
**/

// Clearfix

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  transform-style: preserve-3d;
}

@mixin button {
  display: inline-block;
  border: none;
  background: $green;
  padding: 10px 18px;
  cursor: pointer;
  color: $white;
  @include font-size(12px);
  font-family: $fontTitle;
  font-weight: $bold;
  text-transform: uppercase;
  transition: all 0.3s;
  &:hover {
    background: $yellow;
    color: $white;
  }
}

@mixin icon {
  font-family: "fontastic-custom-icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}