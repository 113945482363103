/**
*
* Opportunities
*
**/

// Opportunities View

.not-front .view-opps .views-row {
  @include clearfix;
  padding: 1.5em 0;
  border-bottom: 1px solid $grey-200;
  &:last-child {
    border-bottom: none;
  }
}

h3.opp-content-title {
  margin: 0 0 0.5em;
  .title-label {
    font-weight: $normal;
    color: $grey-600;
  }
}

.opp-image {
  display: inline-block;
  width: 50px;
  @include mq($from: tablet) {
    width: 100px;
  }

  margin: 0 1em 0 0;
  vertical-align: top;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.view-group .opp-content-container {
  display: inline-block;
  width: 75%;
}

.opp-description {
  @include font-size(13px);
  margin: 0 0 0.25em;
}

.opp-content-meta-data-one {
  margin: 0 0 0.25em;
  font-family: $fontTitle;
  // font-weight: $bold;
  strong {
    color: $grey-500;
  }
}

.opp-content-meta-data-two {
  margin: 0 0 0.25em;
  font-family: $fontTitle;
  font-weight: $bold;
}

.opp-content-meta-data-two a {
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid $grey-200;
  color: $grey-400;
  &:hover {
    color: $green;
  }
  &:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.opp-content-meta-data-three {
  font-family: $fontTitle;
  .opp-started {
    padding: 0 0.5em 0 0;
  }
  .follow-count {
    padding: 0 0 0 0.5em;
    border-left: 1px solid $grey-200;
  }
}

.opp-countries {
  font-family: $fontTitle;
  font-weight: $bold;
  text-transform: uppercase;
  padding: 0 0.5em 0 0;
  margin: 0 0.25em 0 0;
  border-right: 1px solid $grey-200;
  a {
    color: $yellow;
  }
}

.opp-list-map-pane {
  .pane-title {
    @include font-size(18px);
    padding: 0.75em;
    margin: 0;
    background: $grey-100;
    border: 1px solid $grey-200;
    border-bottom: none;
  }
  .pane-content {
    padding: 1em;
    margin: 0 0 1.5em;
    border: 1px solid $grey-200;
  }
}

// Opportunities page

.pane-opportunity-image-and-video-view {
  margin: 0 0 1.5em;
  img {
    width: 100%;
  }
}

.pane-group-field-short-description {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
}

.pane-group-field-description {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
}

.opp-links-pane {
  margin: 0 0 1.5em;
  padding: 0 0 1.5em;
  border-bottom: 1px solid $grey-200;
  .field-facebook-account-container,
  .field-twitter-link-container,
  .field-linkedin-container {
    font-family: $fontTitle;
    @include font-size(16px);
    display: inline-block;
    margin: 0 1em 0 0;
    padding: 0 1em 0 0;
    border-right: 1px solid $grey-200;
  }
  .field-linkedin-container {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.opp-links-pane .field-link-container {
  margin: 0 0 1em 0;
  font-weight: $bold;
}

.opp-share-this-pane {
  margin: 0 0 0.5em;
}

// Right Column

// Right blocks

.opp-date-created-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid $grey-200;
  font-family: $fontTitle;
  @include font-size(16px);
  .date-created-label {
    font-weight: $bold;
    color: $grey-400;
  }
}

.opp-seeking-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid $grey-200;
  font-family: $fontTitle;
  @include font-size(16px);
  .field-label {
    font-weight: $bold;
    color: $grey-400;
  }
}

.opp-status-pane {
  padding: 1em;
  margin: 0 0 1.5em;
  border: 1px solid $grey-200;
  font-family: $fontTitle;
  @include font-size(16px);
  .pane-title {
    font-weight: $bold;
    color: $grey-400;
  }
  .pane-content {
    display: inline-block;
  }
}